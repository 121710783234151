import {parseDate, uuid4} from '@/Utility/Helpers';
import AssetType from '@/Models/Asset/AssetType';
import AssetPermissionPolicy, {
    AssetPolicySample,
    AssetPolicyStandard,
    AssetPolicyTemplate,
    AssetPolicyUseOnly
} from '@/Models/Asset/AssetPermissionPolicy';
import type AssetFile from '@/Models/Asset/AssetFile';

export default abstract class Asset {

    static get constructorName() {
        return 'Asset';
    }

    public readonly uid: string;
    public readonly title: string;
    public readonly description: string | null;
    public readonly attribution: string | null;
    public readonly content_description: string | null;
    public readonly type: string;
    public readonly policy: string;
    public readonly archived: boolean;

    /**
     * Preview image URL
     */
    public preview: string | null;

    /**
     * Thumbnail image URL
     */
    public thumbnail: string | null;

    public readonly bundle: boolean;

    public readonly created_at: Date | null;
    public readonly updated_at: Date | null;
    public readonly fetched_at: Date | null;

    /**
     * Owner tenant of this asset (uid)
     */
    public readonly owned_by_tenant: string | null;

    /**
     * List of tenant-uids that have access to this asset
     */
    public readonly tenants: string[] | null;

    public readonly selected: boolean;

    /**
     * Preview image File instance for uploading (hidden)
     */
    private preview_upload_file: File | null;


    constructor(attributes: any = {}) {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        attributes = (attributes instanceof Object && !(attributes instanceof Array)) ? attributes : {};

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        ['preview_upload_file', 'selected'].forEach(attribute => Object.defineProperty(
            this,
            attribute,
            { enumerable: false, writable: true }
        ));

        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || !AssetType.isValidType(attributes.type)) {
            console.warn('Asset->constructor(): Invalid data.', attributes);
            throw new TypeError(
                'Asset->constructor(): Property "type" has to be set on Asset. Must be a valid type from AssetType class.'
            );
        }

        // Populate the model:
        this.uid = attributes.uid || uuid4();
        this.title = attributes.title || null;
        this.description = attributes.description || null;
        this.attribution = attributes.attribution || null;
        this.content_description = attributes.content_description || null;
        this.type = attributes.type || null;
        this.policy = attributes.policy;
        this.archived = (typeof attributes.archived === 'boolean') ? attributes.archived : false;

        this.preview = attributes.preview || null;
        this.thumbnail = attributes.thumbnail || null;

        this.bundle = (typeof attributes.bundle === 'boolean') ? attributes.bundle : false;

        // Timestamps
        this.created_at = parseDate(attributes.created_at || null);
        this.updated_at = parseDate(attributes.updated_at || null);

        // Tenancy
        this.owned_by_tenant = attributes.owned_by_tenant || null;
        this.tenants = attributes.tenants || null;

        // Runtime State
        this.selected = (typeof attributes.selected === 'boolean') ? attributes.selected : false;
        this.fetched_at = parseDate(attributes.fetched_at || null);

        // Upload
        this.preview_upload_file = attributes.preview_upload_file || null;
    }

    abstract get fileList(): AssetFile[];

    /**
     * Checks if the asset supports user uploaded preview images
     */
    abstract get supportsPreviewImage(): boolean;

    /**
     * Get the constructor name from the instance's class
     */
    get constructorName(): string {
        return Asset.constructorName;
    }

    /**
     * Is this asset an environment asset?
     */
    get isEnvironment(): boolean {
        return this.type.toLowerCase().includes('environment');
    }

    /**
     * AssetPolicy for this asset
     */
    get assetPolicy(): AssetPermissionPolicy {
        return AssetPermissionPolicy.getAssetPolicyForType(this.policy);
    }

    /**
     * AssetType for this asset
     */
    get assetType(): AssetType {
        const assetType = AssetType.getByTypeName(this.type);

        if (assetType === null) {
            throw new Error(`No matching type found for asset type ${assetType}.`);
        }

        return assetType;
    }

    /**
     * Title of the AssetType
     */
    get assetTypeTitle(): string {
        return this.assetType.title;
    }

    /**
     * Whether preview is supported for this asset
     */
    get supportsPreview(): boolean {
        return (
            this.assetType.supportsPreview &&
            !this.bundle
        );
    }

    /**
     * Checks if the asset has a user uploaded preview image
     */
    get hasPreviewImage(): boolean {
        return (
            this.supportsPreviewImage &&
            this.preview !== null &&
            !this.preview.includes(this.assetType.preview)
        );
    }

    /**
     * Get preview image for uploading
     */
    get previewImageForUpload(): File | null {
        return this.preview_upload_file;
    }

    /**
     * Set preview image for uploading
     */
    set previewImageForUpload(file: File | null) {
        this.preview_upload_file = (file instanceof File) ? file : null;
    }

    /**
     * Check if the asset is of a given type
     */
    typeOf(assetType: AssetType): boolean {
        return (
            assetType instanceof AssetType &&
            this.type === assetType.type
        );
    }

    get isArchived(): boolean {
        return this.archived;
    }

    /**
     * Checks if this asset is available for the user's library
     */
    get isInUserLibrary(): boolean {
        if (this.isArchived) {
            return false;
        }
        if (this.isFromFreeLibrary) {
            return this.isObtainedByTenant;
        }
        return true;
    }

    /**
     * Checks if this asset is from the store
     */
    get isFromFreeLibrary(): boolean {
        return this.policy === AssetPolicyUseOnly.type;
    }

    /**
     * Checks if this asset has been obtained by the current users tenant
     */
    get isObtainedByTenant(): boolean {
        if (window.currentUser && window.currentUser.tenant) {
            return this.tenants?.includes(window.currentUser.tenant.uid) || false;
        }
        return false;
    }

    /**
     * Checks if this asset is a sample asset
     */
    get isSampleAsset(): boolean {
        return this.policy === AssetPolicySample.type;
    }

    /**
     * Checks if this asset is a template asset
     */
    get isTemplateAsset(): boolean {
        return this.policy === AssetPolicyTemplate.type;
    }

    /**
     * Checks if this asset is a standard asset
     */
    get isStandardAsset(): boolean {
        return this.policy === AssetPolicyStandard.type;
    }
}
