<template>
    <div class="artisan-command-list-item">
        <div class="artisan-command-list-item-info">

            <h3 class="artisan-command-list-item-info-title">
                {{ command.name }}
            </h3>
            <p class="artisan-command-list-item-info-description">
                {{ command.description }}
            </p>

            <ButtonPrimary
                caption="artisan_commands.run"
                @trigger="onClickRunCommand"
            />

            <div
                v-if="logs"
                :class="{ error: hasErrors, success: !hasErrors }"
                class="artisan-command-list-item-command-output"
            >
                <label>{{ trans('artisan_commands.command_output') }}</label>

                <textarea
                    :value="logs.join('\n')"
                    class="form-control logs"
                    rows="5"
                />
            </div>
        </div>
    </div>

</template>

<script lang="ts">
import ButtonPrimary from '@/Vue/Common/ButtonPrimary';
import {defineComponent, inject} from 'vue';
import {manageServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import {trans} from '../../Utility/Helpers.js';

export default defineComponent({
    name: 'ArtisanCommandListItem',

    components: {
        ButtonPrimary
    },

    props: {
        command: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            manageService: inject(manageServiceKey)!,
            logs: null as string[] | null,
            hasErrors: false,
        };
    },

    methods: {
        trans,

        onClickRunCommand() {
            this.hasErrors = false;
            this.logs = null;

            this.manageService.runCommand(this.command.name)
                .then(logs => {
                    this.logs = logs;
                })
                .catch(error => {
                    this.hasErrors = true;

                    // Handle Validation errors
                    if (error.response && error.response.data) {
                        this.logs = error.response.data.toString().split('\n');
                    } else {
                        this.$root!.showErrorDialog(error);
                    }
                });
        }
    }
});
</script>

<style lang="css" scoped>

.artisan-command-list-item {
    background: var(--color-white);
    border-radius: var(--card-border-radius);
    overflow: hidden;
    transition: box-shadow 0.15s ease-in-out;

    padding: 20px;
}

.artisan-command-list-item + .artisan-command-list-item {
    margin-top: 20px;
}

.artisan-command-list-item-command-output {
    margin-top: 20px;

    &.error {
        textarea {
            border-color: var(--color-signal);
        }
    }

    &.success {
        textarea {
            border-color: var(--color-item-green);
        }
    }
}

textarea {
    min-height: 200px !important;
    height: auto;
    padding: 8px;
    font-family: var(--font-family-mono-regular);
    font-size: var(--font-size-small);
    white-space: pre;
    field-sizing: content;
}

</style>
