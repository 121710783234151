<template>
    <Modal
        ref="modal"
        :animated="true"
        :show-close-button="false"
        :title="trans('modals.change_tenant_role.title')"
        :user-closable="true"
        event-type="MODAL_CHANGE_TENANT_ROLE"
        @hide="onHide"
        @show="onShow"
    >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="description" />

        <Dropdown
            :key="`tenant-dropdown-${currentTenantRole?.uid}`"
            :model="form"
            :options="tenantRoleOptions"
            property="newTenantRoleUid"
        />

        <template #buttons>
            <ButtonSecondary
                caption="labels.cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                :disabled="currentTenantRole?.uid === form.newTenantRoleUid"
                caption="labels.save"
                @trigger="onClickSave"
            />
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, inject, type PropType} from 'vue';
import Modal from '@/Vue/Modals/Modal.vue';
import {trans} from '@/Utility/Helpers/trans';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import type Tenant from '@/Models/Tenant/Tenant';
import type User from '@/Models/User/User';
import type {TenantRole} from '@/Models/Tenant/TenantRole';
import Dropdown from '@/Vue/Common/Dropdown.vue';
import DropdownOption from '@/Utility/DropdownOption';
import {tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import EventType from '@/Utility/EventType';

export default defineComponent({

    components: {
        Dropdown,
        ButtonSecondary,
        ButtonPrimary,
        Modal,
    },

    props: {
        tenantRoles: {
            type: Array as PropType<TenantRole[]>,
            required: true,
        }
    },

    data() {
        return {
            tenantService: inject(tenantServiceKey)!,
            user: null as User | null,
            tenant: null as Tenant | null,
            form: {
                newTenantRoleUid: null as string | null,
            }
        };
    },

    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },

        description() {
            if (!this.user || !this.tenant || !this.currentTenantRole) {
                return '';
            }

            return trans('modals.change_tenant_role.description', {
                'email': this.user.email,
                'tenant_name': this.tenant.name,
                'tenant_role_name': this.currentTenantRole.caption,
            });
        },

        currentTenantRole(): TenantRole | null {
            if (!this.user || !this.tenant) {
                return null;
            }

            return this.user.getHighestRegularTenantRole(this.tenant);
        },

        newTenantRole(): TenantRole | null {
            return this.tenantRoles.find(tr => tr.uid === this.form.newTenantRoleUid) || null;
        },

        tenantRoleOptions(): DropdownOption[] {
            return this.tenantRoles.map(role => new DropdownOption({
                caption: role.caption,
                value: role.uid
            }));
        }
    },

    methods: {
        trans,

        onShow(user: User, tenant: Tenant) {
            this.user = user;
            this.tenant = tenant;
            this.form.newTenantRoleUid = this.currentTenantRole?.uid || null;
        },

        onHide() {
            this.user = null;
            this.tenant = null;
            this.form.newTenantRoleUid = null;
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickSave() {
            if (!this.user || !this.tenant || !this.newTenantRole) {
                return;
            }

            this.tenantService
                .updateTenantRole(this.tenant, this.user, this.newTenantRole)
                .then((user: User) => {
                    this.modal.hide();
                    this.$globalEvents.emit(EventType.MODAL_CHANGE_TENANT_ROLE_APPLY, user, this.newTenantRole);
                })
                .catch(this.onApiError);
        },

        onApiError(error: string | Error) {
            this.modal.hide();
            this.$root!.showErrorDialog(error);
        },
    },
});
</script>

<style lang="css" scoped>

.dropdown-element {
    display: block;
    margin-top: 12px;
}

dialog > :deep(.buttons) {
    margin-top: 76px;
}

</style>
