import {defineAsyncComponent} from 'vue';

// Register global components:
import ButtonCircular from '@/Vue/Common/ButtonCircular';
import ButtonCustom from '@/Vue/Common/ButtonCustom';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary';
import Checkbox from '@/Vue/Common/Checkbox';
import Collapsible from '@/Vue/Common/Collapsible';
import ColorHexInput from '@/Vue/Common/ColorHexInput';
import Draggable from '@/Vue/Common/Draggable';
import Dropdown from '@/Vue/Common/Dropdown';
import Icon from '@/Vue/Common/Icon';
import Modal from '@/Vue/Modals/Modal';
import ModalAbout from '@/Vue/Modals/ModalAbout';
import ModalInstallation from '@/Vue/Modals/Installation/ModalInstallation';
import ModalSelectTenant from '@/Vue/Modals/SelectTenant/ModalSelectTenant';
import NumberInput from '@/Vue/Common/NumberInput';
import PageHeader from '@/Vue/Common/PageHeader';
import Pagination from '@/Vue/Common/Pagination';
import RadioButtons from '@/Vue/Common/RadioButtons';
import StatusLabel from '@/Vue/Common/StatusLabel';
import Tabs from '@/Vue/Common/Tabs';
import TagList from '@/Vue/Common/TagList';
import TextInput from '@/Vue/Common/TextInput';
import TextOutputField from '@/Vue/Common/TextOutputField';

// Register components for Laravel views:
import ApiTokenPage from '@/Vue/Tokens/ApiTokenPage';
import ArtisanCommands from '@/Vue/Admin/ArtisanCommands';
import CreateAssetForm from '@/Vue/Assets/CreateAssetForm';
import CreateUserForm from '@/Vue/Users/CreateUserForm';
import DeleteUserForm from '@/Vue/Users/DeleteUserForm';
import FeaturesTable from '@/Vue/Admin/FeaturesTable';
import InstanceUserList from '@/Vue/Admin/InstanceUserList';
import PageAssetList from '@/Vue/Assets/PageAssetList';
import PageCourseCreate from '@/Vue/Courses/PageCourseCreate';
import PageCourseEdit from '@/Vue/Courses/PageCourseEdit';
import PageCourseList from '@/Vue/Courses/PageCourseList';
import PageCourseView from '@/Vue/Courses/PageCourseView';
import PageIdentityProviderCreate from '@/Vue/IdentityProviders/PageIdentityProviderCreate';
import PageIdentityProviderEdit from '@/Vue/IdentityProviders/PageIdentityProviderEdit';
import PageIdentityProviderIndex from '@/Vue/IdentityProviders/PageIdentityProviderIndex';
import PageIdentityProviderShow from '@/Vue/IdentityProviders/PageIdentityProviderShow';
import PageImportList from '@/Vue/Import/PageImportList';
import PageInstanceManagement from '@/Vue/InstanceManagement/PageInstanceManagement';
import PageLmsXApiLaunch from '@/Vue/Lms/xapi/PageLmsXApiLaunch';
import PageLmsXApiLaunchError from '@/Vue/Lms/xapi/PageLmsXApiLaunchError';
import PageLogin from '@/Vue/Auth/PageLogin';
import PageModelViewer from '@/Vue/Tools/ModelViewer/PageModelViewer';
import PagePrivacyPolicyForm from '@/Vue/InstanceManagement/PagePrivacyPolicyForm';
import PageSessionCreate from '@/Vue/Sessions/PageSessionCreate';
import PageSessionEdit from '@/Vue/Sessions/PageSessionEdit';
import PageSessionList from '@/Vue/Sessions/PageSessionList';
import PageStatistics from '@/Vue/Statistics/PageStatistics';
import PageTenantAcceptInvitation from '@/Vue/Tenants/PageTenantAcceptInvitation';
import PageTenantCreate from '@/Vue/Tenants/PageTenantCreate';
import PageTenantList from '@/Vue/Tenants/PageTenantList';
import PageTenantUpdate from '@/Vue/Tenants/PageTenantUpdate';
import PageUnitCreate from '@/Vue/Units/PageUnitCreate';
import PageUnitLaunch from '@/Vue/Units/PageUnitLaunch';
import PageUnitList from '@/Vue/Units/PageUnitList';
import PageUserList from '@/Vue/Users/PageUserList';
import PermissionsTable from '@/Vue/Admin/PermissionsTable';
import SetInitialPasswordPage from '@/Vue/Users/SetInitialPasswordPage';
import SidemenuItem from '@/Vue/Sidemenu/SidemenuItem';
import SidemenuItemUserGuiding from '@/Vue/Sidemenu/SidemenuItemUserGuiding';
import SidemenuItemUserTenant from '@/Vue/Sidemenu/SidemenuItemUserTenant';
import TranslationsTable from '@/Vue/Admin/TranslationsTable';
import UpdateUserForm from '@/Vue/Users/UpdateUserForm';
import UserNavigationItem from '@/Vue/Users/UserNavigationItem';
import Userguiding from '@/Vue/Bootstrap/Userguiding';

// Behaviour Panels
import PanelBehaviourCollidable from '@/Vue/Inspector/Behaviours/PanelBehaviourCollidable';
import PanelBehaviourMovable from '@/Vue/Inspector/Behaviours/PanelBehaviourMovable';
import PanelBehaviourPhysics from '@/Vue/Inspector/Behaviours/PanelBehaviourPhysics';
import PanelBehaviourTeleportable from '@/Vue/Inspector/Behaviours/PanelBehaviourTeleportable';

// Wrapper for behaviours
import PanelBehaviour from '@/Vue/Inspector/Behaviours/PanelBehaviour';

// Variable Panels
import PanelVariableBool from '@/Vue/Inspector/Variables/PanelVariableBool';
import PanelVariableNumber from '@/Vue/Inspector/Variables/PanelVariableNumber';
import PanelVariableOperandNumber from '@/Vue/Inspector/Variables/PanelVariableOperandNumber';

// Wrapper for variables
import PanelVariable from '@/Vue/Inspector/Variables/PanelVariable';

// register components
export function registerComponents(app) {
    // Register global components:
    app.component('ButtonCircular', ButtonCircular);
    app.component('ButtonCustom', ButtonCustom);
    app.component('ButtonPrimary', ButtonPrimary);
    app.component('ButtonSecondary', ButtonSecondary);
    app.component('Checkbox', Checkbox);
    app.component('Collapsible', Collapsible);
    app.component('ColorHexInput', ColorHexInput);
    app.component('Draggable', Draggable);
    app.component('Dropdown', Dropdown);
    app.component('Icon', Icon);
    app.component('ModalAbout', ModalAbout);
    app.component('ModalInstallation', ModalInstallation);
    app.component('ModalSelectTenant', ModalSelectTenant);
    app.component('NumberInput', NumberInput);
    app.component('PageHeader', PageHeader);
    app.component('Pagination', Pagination);
    app.component('RadioButtons', RadioButtons);
    app.component('StatusLabel', StatusLabel);
    app.component('Tabs', Tabs);
    app.component('TagList', TagList);
    app.component('TextInput', TextInput);
    app.component('TextOutputField', TextOutputField);

    // Register components for Laravel views:
    // @NOTE: Only kebab-case is working here since these components are loaded in Laravel views!

    // Load authoring async - this causes vite/rollup to create separate bundles with these dependencies.
    // This will reduce load sizes for learners drastically.
    app.component('authoring', defineAsyncComponent(() => import('@/Vue/Authoring/Authoring')));

    app.component('api-token-page', ApiTokenPage);
    app.component('artisan-commands', ArtisanCommands);
    app.component('create-asset-form', CreateAssetForm);
    app.component('create-user-form', CreateUserForm);
    app.component('delete-user-form', DeleteUserForm);
    app.component('features-table', FeaturesTable);
    app.component('instance-user-list', InstanceUserList);
    app.component('modal', Modal);
    app.component('page-asset-list', PageAssetList);
    app.component('page-course-create', PageCourseCreate);
    app.component('page-course-edit', PageCourseEdit);
    app.component('page-course-list', PageCourseList);
    app.component('page-course-view', PageCourseView);
    app.component('page-identity-provider-create', PageIdentityProviderCreate);
    app.component('page-identity-provider-edit', PageIdentityProviderEdit);
    app.component('page-identity-provider-index', PageIdentityProviderIndex);
    app.component('page-identity-provider-show', PageIdentityProviderShow);
    app.component('page-import-list', PageImportList);
    app.component('page-instance-management', PageInstanceManagement);
    app.component('page-lms-xapi-launch', PageLmsXApiLaunch);
    app.component('page-lms-xapi-launch-error', PageLmsXApiLaunchError);
    app.component('page-login', PageLogin);
    app.component('page-model-viewer', PageModelViewer);
    app.component('page-privacy-policy-form', PagePrivacyPolicyForm);
    app.component('page-session-create', PageSessionCreate);
    app.component('page-session-edit', PageSessionEdit);
    app.component('page-session-list', PageSessionList);
    app.component('page-statistics', PageStatistics);
    app.component('page-tenant-accept-invitation', PageTenantAcceptInvitation);
    app.component('page-tenant-create', PageTenantCreate);
    app.component('page-tenant-list', PageTenantList);
    app.component('page-tenant-update', PageTenantUpdate);
    app.component('page-unit-create', PageUnitCreate);
    app.component('page-unit-launch', PageUnitLaunch);
    app.component('page-unit-list', PageUnitList);
    app.component('page-user-list', PageUserList);
    app.component('permissions-table', PermissionsTable);
    app.component('set-initial-password-page', SetInitialPasswordPage);
    app.component('sidemenu-item', SidemenuItem);
    app.component('sidemenu-item-user-tenant', SidemenuItemUserTenant);
    app.component('sidemenu-item-userguiding', SidemenuItemUserGuiding);
    app.component('translations-table', TranslationsTable);
    app.component('update-user-form', UpdateUserForm);
    app.component('user-navigation-item', UserNavigationItem);
    app.component('userguiding', Userguiding);

    // Behaviour Panels
    // Because of circular dependencies we have to register them globally for now
    app.component('PanelBehaviourCollidable', PanelBehaviourCollidable);
    app.component('PanelBehaviourMovable', PanelBehaviourMovable);
    app.component('PanelBehaviourPhysics', PanelBehaviourPhysics);
    app.component('PanelBehaviourTeleportable', PanelBehaviourTeleportable);

    // Wrapper for behaviours
    // This should be used instead of the individual behaviour panels.
    app.component('PanelBehaviour', PanelBehaviour);

    // Variable Panels
    // Because of circular dependencies we have to register them globally for now
    app.component('PanelVariableBool', PanelVariableBool);
    app.component('PanelVariableNumber', PanelVariableNumber);
    app.component('PanelVariableOperandNumber', PanelVariableOperandNumber);

    // Wrapper for variables
    // This should be used instead of the individual variable panels.
    app.component('PanelVariable', PanelVariable);
}
