<template>

    <nav id="nav-user" data-ug-id="sidemenu-item-user">
        <ul>

            <!-- guest (when logged out) -->
            <li v-if="isGuest" class="nav-item">
                <a
                    :href="route('login')"
                    :title="trans('tooltips.navigation.login')"
                    class="nav-link btn btn-login"
                >
                    {{ trans('labels.login') }}
                </a>
            </li>

            <!-- logged in user -->
            <li v-else class="nav-item extra-menu user-menu">

                <!-- User -->
                <div
                    id="navbarDropdown"
                    ref="navbarDropdown"
                    :title="`${user!.fullName} (${user!.tenant_role!.caption})`"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="nav-link dropdown-toggle"
                    data-bs-auto-close="outside"
                    data-bs-toggle="dropdown"
                    role="button"
                    tabindex="0"
                >

                    <!-- Profile image -->
                    <div class="user-image-crop" :class="{ 'has-image': user!.hasProfileImage }">
                        <img
                            v-if="user!.hasProfileImage"
                            :src="user!.image"
                            alt=""
                            class="user-image"
                        >
                        <div v-else :data-initials="user!.initials" class="user-avatar" />
                    </div>

                </div>

                <!-- extended menu items -->
                <div
                    aria-labelledby="navbarDropdown"
                    class="dropdown-menu dropdown-menu-right"
                >
                    <section class="account">
                        <div class="details">
                            <!-- User name -->
                            <div class="details-name">
                                <b>{{ user!.fullName }}</b>
                            </div>
                            <div class="details-email">
                                {{ user!.email }}
                            </div>
                            <div class="details-role is-grey">
                                {{ user!.tenant_role!.caption }}
                            </div>
                        </div>
                    </section>

                    <section v-if="canUpdateOwnUser" class="settings">
                        <!-- Edit Profile -->
                        <a
                            :href="route('users.updateForm', {'user': user!.uid})"
                            :title="trans('tooltips.navigation.edit_profile')"
                            class="dropdown-item"
                            data-ug-id="sidemenu-item-user-edit"
                        >
                            <Icon name="icon_user" />
                            <span class="label">{{ trans('labels.edit_profile') }}</span>
                        </a>
                    </section>

                    <section class="logout">
                        <!-- Logout -->
                        <a
                            :href="route('logout')"
                            :title="trans('tooltips.navigation.logout')"
                            class="dropdown-item"
                            data-ug-id="sidemenu-item-user-logout"
                        >
                            <Icon name="icon_logout" />
                            <span class="label">{{ trans('labels.logout') }}</span>
                        </a>
                    </section>
                </div>
            </li>
        </ul>
    </nav>

</template>

<script lang="ts">

// Import classes:
import {route, trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import {defineComponent, inject} from 'vue';
import {featureRepositoryKey, tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import Icon from '@/Vue/Common/Icon.vue';
import EventType from '@/Utility/EventType';
import {Dropdown} from '~bootstrap';

export default defineComponent({
    components: {
        Icon
    },

    data() {
        return {
            tenantService: inject(tenantServiceKey)!,
            featureRepository: inject(featureRepositoryKey)!,
        };
    },

    computed: {
        isGuest() {
            return !window.currentUser;
        },

        user() {
            return window.currentUser;
        },

        canUpdateOwnUser() {
            return this.$gate.allows(Permission.ability(Permission.UsersUpdate()), this.user);
        },
    },

    mounted() {
        this.$globalEvents.on(EventType.MODAL_SAVE_OR_DISMISS_CHANGES_SHOW, this.onShowSaveOrDismissDialog);
        this.$globalEvents.on(EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload);
    },

    beforeUnmount() {
        this.$globalEvents.off(EventType.MODAL_SAVE_OR_DISMISS_CHANGES_SHOW, this.onShowSaveOrDismissDialog);
        this.$globalEvents.off(EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload);
    },

    methods: {
        route,
        trans,

        hideMenu() {
            Dropdown.getInstance(this.$refs.navbarDropdown)?.hide();
        },

        onShowSaveOrDismissDialog() {
            this.hideMenu();
        },

        onBeforeUnload() {
            this.hideMenu();
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
        },
    },
});
</script>

<style lang="scss" scoped>

#nav-user {

    a {
        color: var(--color-anthracite);

        &:hover,
        &:focus,
        &:focus-within,
        &:active {
            color: var(--color-primary-hover);
        }
    }

    .nav-link {
        padding: 12px 2px 7px 2px;
        cursor: pointer;

        &:hover,
        &:focus,
        &:focus-within,
        &:active {
            .icon-more,
            .dropdown-item {
                color: var(--color-primary-hover);
            }

            .user-image-crop {
                border-color: var(--color-anthracite40);
                background-color: var(--color-anthracite40);

                &.has-image {
                    border-color: var(--color-anthracite20);
                    background-color: var(--color-anthracite20);
                }

                .user-avatar {
                    &[data-initials]:before {
                        background-color: var(--color-anthracite40);
                    }
                }
            }
        }
    }

    .user-menu {
        .dropdown-menu {
            top: auto !important;
            bottom: 10px !important;
        }
    }

    .user-image-crop {
        width: 44px;
        height: 44px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin: 0 auto 4px auto;
        border: 2px solid var(--color-anthracite80);
        background-color: var(--color-anthracite80);

        &.has-image {
            border: 2px solid var(--color-anthracite10);
            background-color: var(--color-anthracite10);
        }

        .user-avatar {
            width: 40px;
            height: 40px;

            &[data-initials]:before {
                color: var(--color-white);
                background-color: var(--color-anthracite80);
                font-family: var(--font-family-condensed-demibold);
                font-size: var(--font-size-default);
                opacity: 1;
                content: attr(data-initials);
                display: inline-block;
                border-radius: 50%;
                vertical-align: middle;
                margin-right: 0.5em;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                text-transform: uppercase;
            }
        }

        .user-image {
            display: inline-block;
            margin: 0;
            height: auto;
            width: 100%;
        }
    }

    .btn-login {
        min-width: 100%;
        padding-bottom: 20px;
    }
}

</style>
