import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import AxiosRequest from '@/Services/AxiosRequest';
import {route} from '@/Utility/Helpers';
import Tenant from '@/Models/Tenant/Tenant';
import type User from '@/Models/User/User';
import ScimGroup from '@/Models/SCIM/ScimGroup';
import type {TenantRole} from '@/Models/Tenant/TenantRole';
import {TenantMemberRole} from '@/Models/Tenant/TenantMemberRole';
import PagingPage from '@/Models/PagingPage';
import PagingMetadata from '@/Models/PagingMetadata';

export type TenantForm = {
    name: string,
    hubspot_company_id: string | null,
    logo: File | null,
    initialLogoUrl: string | null,
};

export default class TenantService {

    public tenants: Tenant[] = [];

    /**
     * Currently loaded page for instance tenants.
     */
    public instanceTenantsPage = new PagingPage<Tenant>([], new PagingMetadata());

    public isLoading: boolean = false;
    public isSaving: boolean = false;
    public isDeleting: boolean = false;
    private request: AxiosRequest | null = null;

    /**
     * Cancel any ongoing requests.
     */
    async cancelRequests(): Promise<any> {
        // @NOTE: Only working with a single request at the moment!
        return this.request?.cancel();
    }

    /**
     * Fetch all tenants for the current user from API
     *
     * @param user user to fetch tenants for; defaults to current user
     */
    async fetchTenants(user: User | null = null): Promise<Tenant[]> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        user = user || window.currentUser!;

        this.isLoading = true;
        this.request = new AxiosRequest();

        const indexRoute = route('api.users.tenants.index', { 'user': user.uid });

        return this.request
            .get(indexRoute)
            .then(({ data }) => {
                this.tenants = [];
                data.forEach(tenantData => {
                    try {
                        this.tenants.push(new Tenant(tenantData));
                    } catch (ex) {
                        console.warn(
                            'TenantService->fetchTenants(): Skipping tenant with invalid or incompatible data.',
                            tenantData,
                            ex
                        );
                    }
                });
                return this.tenants;
            })
            .catch(error => {
                console.error('TenantService->fetchTenants():', error);
                throw error;
            })
            .finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Fetch all tenants on this instance.
     */
    async fetchInstanceTenants(
        page: number = 1,
        search: string | null = null,
        orderBy: string | null = null,
        descending: boolean = false
    ): Promise<PagingPage<Tenant>> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        const params = {
            page: page,
            search: search,
            sort: orderBy,
            sort_order: 'asc',
        };

        if (descending) {
            params.sort_order = 'desc';
        }

        return this.request
            .get(route('api.tenants.index'), { params: params })
            .then(({ data }) => {
                const pagingMetadata = new PagingMetadata(data.meta);

                const tenants = data.data
                    .map((tenantData: any) => {
                        try {
                            return new Tenant(tenantData);
                        } catch (ex) {
                            console.warn(
                                'TenantService->fetchInstanceTenants(): Skipping tenant with invalid or incompatible data.',
                                tenantData,
                                ex
                            );
                            return null;
                        }
                    })
                    .filter((tenant: Tenant | null) => tenant !== null);

                this.instanceTenantsPage = new PagingPage(tenants, pagingMetadata);
                return this.instanceTenantsPage;
            })
            .catch(error => {
                console.error('TenantService->fetchInstanceTenants():', error);
                throw error;
            })
            .finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Switches current user to work in the given tenant and reloads the page
     * to update user permissions. The user may navigate to another page if they
     * no longer have access to the current page.
     *
     * @param tenant tenant to switch to
     * @param redirectUrl if given, the user will be redirected to this url
     * after switching successfully
     */
    async switchTenantAndReload(tenant: Tenant, redirectUrl: string | null = null): Promise<any> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        const oldTenant = window.currentUser!.tenant;
        window.currentUser!.tenant = tenant;

        const switchTenantRoute = route('user.tenants.current.put');
        redirectUrl ??= window.location.href;

        return this.request
            .put(switchTenantRoute)
            .then(() => new AxiosRequest()
                .head(redirectUrl)
                .then(() =>
                    // we are still allowed to access target page - reload
                    this.redirectTo(redirectUrl)
                )
                .catch(() =>
                    // we are no longer allowed to access target page - navigate to home
                    this.redirectTo('/')
                )
            )
            .catch(error => {
                this.isLoading = false;
                this.request = null;

                window.currentUser!.tenant = oldTenant;
                console.error('TenantService->switchTenant():', error);
                throw error;
            });
    }

    async createTenant(data: TenantForm): Promise<Tenant> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        const createTenantRoute = route('api.tenants.create');

        const shallowFormData = this.toFormData(data);
        const formData = new FormData();

        // nest inside tenant node
        shallowFormData.forEach((value, key) => {
            formData.set(`tenant[${key}]`, value);
        });

        return this.request
            .post(createTenantRoute, formData)
            .then(({ data }) => {
                return new Tenant(data.tenant);
            })
            .catch(error => {
                console.error('TenantService->createTenant():', error);
                throw error;
            })
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    async updateTenant(uid: string, data: TenantForm): Promise<Tenant> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        const updateTenantRoute = route('api.tenants.update', { tenant: uid });

        const formData = this.toFormData(data);
        // change method to support file uploads
        formData.append('_method', 'PATCH');

        return this.request
            .post(updateTenantRoute, formData)
            .then(({ data }) => {
                return new Tenant(data.data);
            })
            .catch(error => {
                console.error('TenantService->updateTenant():', error);
                throw error;
            })
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    /**
     * Deletes the given tenant permanently.
     */
    async deleteTenant(tenant: Tenant): Promise<any> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isDeleting = true;
        this.request = new AxiosRequest();

        const deleteTenantRoute = route('api.tenants.delete', { tenant: tenant.uid });

        return this.request
            .delete(deleteTenantRoute)
            .catch(error => {
                console.error('TenantService->deleteTenant():', error);
                throw error;
            })
            .finally(() => {
                this.isDeleting = false;
                this.request = null;
            });
    }

    async addUser(tenant: Tenant, email: string, role: string): Promise<any> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Service is busy');
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        const addUserToTenantRoute = route('api.tenants.users.add', { tenant: tenant.uid });
        const data = {
            email: email,
            tenant_role_uid: role,
        };

        return this.request
            .post(addUserToTenantRoute, data)
            .catch(error => {
                console.error('TenantService->addUserTenant():', error);
                throw error;
            })
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    async inviteUser(tenant: Tenant, email: string, role: string): Promise<any> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Service is busy');
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        const inviteUserToTenantRoute = route('api.tenants.users.invitations.create', { tenant: tenant.uid });
        const data = {
            email: email,
            tenant_role_uid: role,
        };

        return this.request
            .post(inviteUserToTenantRoute, data)
            .catch(error => {
                console.error('TenantService->inviteUser():', error);
                throw error;
            })
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    async removeUser(tenant: Tenant, user: User): Promise<any> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Service is busy');
        }

        this.isDeleting = true;
        this.request = new AxiosRequest();

        return this.request
            .delete(route('api.tenants.users.remove', { tenant: tenant.uid, user: user.uid }))
            .catch(error => {
                console.error('TenantService->removeUser():', error);
                throw error;
            })
            .finally(() => {
                this.isDeleting = false;
                this.request = null;
            });
    }

    async updateTenantRole(tenant: Tenant, user: User, tenantRole: TenantRole): Promise<User> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Service is busy');
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        const data = { 'tenant_role_uid': tenantRole.uid };

        return this.request
            .patch(route('api.tenants.users.update', { tenant: tenant.uid, user: user.uid }), data)
            .then(() => {

                // Replace tenant member role with the new role:
                const roleIndex = user.tenant_member_roles?.findIndex((tr: TenantMemberRole) => !tr.isScimMapped && (tr.tenant_uid === tenant.uid)) ?? -1;
                const tenantMemberRole = user.tenant_member_roles![roleIndex] || null;
                if (tenantMemberRole) {
                    user.tenant_member_roles!.splice(
                        roleIndex,
                        1,
                        new TenantMemberRole({
                            ...tenantMemberRole,
                            role: tenantRole
                        })
                    );
                }

                user.tenant_role = tenantRole;

                return user;
            })
            .catch(error => {
                console.error('TenantService->updateTenantRole():', error);
                throw error;
            })
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    async fetchGroupsForTenant(tenant: Tenant | null = null): Promise<ScimGroup[]> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        tenant = tenant || window.currentUser!.tenant!;
        const indexRoute = route('api.tenants.groups.index', { 'tenant': tenant.uid });

        return this.request
            .get(indexRoute)
            .then(response => {
                const groups: ScimGroup[] = [];
                response.data?.data?.forEach((groupData: any) => {
                    try {
                        groups.push(new ScimGroup(groupData));
                    } catch (ex) {
                        console.warn(
                            'TenantService->fetchGroupsForTenant(): Skipping group with invalid or incompatible data.',
                            groupData,
                            ex
                        );
                    }
                });
                return groups;
            })
            .catch(error => {
                console.error('TenantService->fetchGroupsForTenant():', error);
                throw error;
            })
            .finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    async addGroupToTenant(group: ScimGroup, role: TenantRole, tenant: Tenant | null = null): Promise<ScimGroup> {
        if (this.isSaving || this.request?.isBusy) {
            throw new ServiceIsBusyError('Service is busy');
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        tenant = tenant || window.currentUser!.tenant!;
        const addGroupToTenantRoute = route('api.tenants.groups.add', { tenant: tenant.uid });
        const data: { group_uid: string, tenant_role_uid: string } = {
            group_uid: group.uid,
            tenant_role_uid: role.uid,
        };

        return this.request
            .post(addGroupToTenantRoute, data)
            .then(response => {
                return new ScimGroup(response.data);
            })
            .catch(error => {
                console.error('TenantService->addGroupToTenant():', error);
                throw error;
            })
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    async removeGroupFromTenant(group: ScimGroup, tenant: Tenant | null = null): Promise<void> {
        if (this.isDeleting || this.request?.isBusy) {
            throw new ServiceIsBusyError('Service is busy');
        }

        this.isDeleting = true;
        this.request = new AxiosRequest();

        tenant = tenant || window.currentUser!.tenant!;

        return this.request
            .delete(
                route('api.tenants.groups.remove', { tenant: tenant.uid, group: group.uid })
            )
            .catch(error => {
                console.error('TenantService->removeGroupFromTenant():', error);
                throw error;
            })
            .finally(() => {
                this.isDeleting = false;
                this.request = null;
            });
    }

    private toFormData(data: TenantForm): FormData {
        const formData = new FormData();
        formData.set('name', data.name);

        if (data.hubspot_company_id) {
            formData.set('hubspot_company_id', data.hubspot_company_id);
        }
        if (data.logo) {
            formData.set('logo', data.logo, data.logo.name);
        }
        return formData;
    }

    private redirectTo(url: string) {
        if (url === window.location.href) {
            window.location.reload();
        } else {
            window.location.href = url;
        }
    }
}
