<template>
    <div class="row">
        <div>
            <img
                :class="{empty: !tenant.logo_thumbnail}"
                :src="tenant.nonEmptyLogoThumbnail"
                alt=""
                class="logo"
                loading="lazy"
            >
        </div>
        <div>
            <Icon v-if="tenant.isAnyDefaultTenant" name="icon_admin" />
        </div>
        <div class="name">
            {{ tenant.name }}
        </div>
        <div class="uid">
            {{ tenant.uid }}
        </div>
        <time :datetime="moment(tenant.created_at).format()">
            {{ tenant.created_at.toLocaleString() }}
        </time>
        <time :datetime="moment(tenant.updated_at).format()">
            {{ tenant.updated_at.toLocaleString() }}
        </time>
        <div class="tenant-actions">
            <ButtonCircular
                v-if="canSwitchTo"
                :title="trans('tenants.index.change_team_button')"
                icon="icon_login"
                @trigger="onClickSwitchTenant"
            />
            <ButtonCircular
                v-if="canUpdate"
                :href="route('tenants.updateForm', {tenant: tenant.uid})"
                :title="trans('tenants.index.settings_button')"
                icon="icon_settings"
            />
        </div>
        <div class="tenant-actions">
            <ButtonCircular
                :disabled="!canDelete"
                :title="trans('tenants.index.delete_button')"
                icon="icon_delete"
                @trigger="onClickDeleteTenant"
            />
        </div>
    </div>
</template>

<script lang="ts">

import {route, trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import {defineComponent} from 'vue';
import Tenant from '@/Models/Tenant/Tenant';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import Icon from '@/Vue/Common/Icon.vue';

export default defineComponent({
    name: 'TenantListItem',

    components: {
        Icon,
        ButtonCircular,
    },

    props: {
        tenant: {
            type: Tenant,
            required: true
        },
    },

    emits: [
        'switch',
        'delete',
    ],

    computed: {
        canSwitchTo() {
            if (this.isCurrentTenant) {
                return false;
            }

            return window.currentUser?.isMemberOfTenant(this.tenant) ||
                this.$gate.allows(Permission.ability(Permission.TenantsImpersonateRoleAdmin()), this.tenant);
        },

        canUpdate() {
            return this.$gate.allows(Permission.ability(Permission.TenantsUpdate()), this.tenant);
        },

        canDelete() {
            return this.$gate.allows(Permission.ability(Permission.TenantsDelete()), this.tenant);
        },

        isCurrentTenant() {
            return this.tenant.uid === window.currentUser?.tenant?.uid;
        },

        shouldShowTenantUid() {
            return this.$gate.allows(Permission.TenantsDisplayUid());
        },
    },

    methods: {
        route,
        trans,

        onClickSwitchTenant() {
            this.$emit('switch', this.tenant);
        },

        onClickDeleteTenant() {
            this.$emit('delete', this.tenant);
        }
    }
});
</script>

<style lang="css" scoped>

.row {
    background-color: white;

    > * {
        display: flex;
        align-items: center;

        &.name {
            font-family: var(--font-family-condensed-demibold);
        }

        &.uid {
            font-family: var(--font-family-mono-regular);
            word-break: break-word;
        }

        .logo {
            width: 100%;
            border-radius: var(--forminput-border-radius);
            border: 1px solid var(--color-anthracite20);
            background-color: white;

            &.empty {
                aspect-ratio: 16/9;
            }
        }

        .icon {
            height: 16px;
            width: 16px;
            flex-basis: 16px;
        }
    }
}

</style>
