import {parseDate} from '@/Utility/Helpers';

export default class Tenant {

    get constructorName() {
        return 'Tenant';
    }

    public readonly uid: string;
    public readonly name: string;
    public readonly logo: string | null;
    public readonly logo_thumbnail: string | null;
    public readonly hubspot_company_id: string | null;
    public readonly is_default_tenant: boolean;
    public readonly is_default_admin_tenant: boolean;
    public readonly is_default_asset_tenant: boolean;
    public readonly created_at: Date;
    public readonly updated_at: Date;

    constructor(attributes: any) {
        this.uid = attributes.uid;
        this.name = attributes.name;
        this.logo = attributes.logo;
        this.logo_thumbnail = attributes.logo_thumbnail;
        this.hubspot_company_id = attributes.hubspot_company_id;
        this.is_default_tenant = attributes.is_default_tenant;
        this.is_default_admin_tenant = attributes.is_default_admin_tenant;
        this.is_default_asset_tenant = attributes.is_default_asset_tenant;
        this.created_at = parseDate(attributes.created_at)!;
        this.updated_at = parseDate(attributes.updated_at)!;
    }

    get isAnyDefaultTenant() {
        return this.is_default_tenant || this.is_default_admin_tenant || this.is_default_asset_tenant;
    }

    /**
     * Logo thumbnail image path with an empty svg data element as fallback.
     * Can safely be used as image source across all browser.
     */
    get nonEmptyLogoThumbnail() {
        return this.logo_thumbnail ?? 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"/>';
    }
}
