import {parseDate, trans} from '@/Utility/Helpers';

export class TenantRole {

    public readonly uid: string;
    public readonly name: TenantRole.Name;
    public readonly created_at: Date;
    public readonly updated_at: Date;

    constructor(attributes: any = {}) {
        this.uid = attributes.uid;
        this.name = attributes.name;
        this.created_at = parseDate(attributes.created_at)!;
        this.updated_at = parseDate(attributes.updated_at)!;
    }

    get caption(): string {
        return trans(`tenant_roles.${this.name}`);
    }

    is(name: TenantRole.Name): boolean {
        return this.name === name;
    }

    isAnyOf(...names: TenantRole.Name[]): boolean {
        return names.includes(this.name);
    }
}

export namespace TenantRole {
    export enum Name {
        Admin = 'admin',
        Author = 'author',
        AuthorLimited = 'author_limited',
        Learner = 'learner',
    }
}
