import {parseDate, uuid4} from "@/Utility/Helpers";

export default class LocalFeature {

    public readonly uid: string;
    public readonly name: string | null;
    public readonly tenant_uid: string | null;
    public readonly created_at: Date | null;
    public readonly updated_at: Date | null;

    constructor(attributes: any = {}) {
        this.uid = attributes.uid || uuid4();
        this.created_at = parseDate(attributes.created_at || null);
        this.updated_at = parseDate(attributes.updated_at || null);
        this.name = attributes.name || null;
        this.tenant_uid = attributes.tenant_uid || null;
    }

    get isInstanceFeature() {
        return this.tenant_uid === null;
    }
}
