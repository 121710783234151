import {trans} from '@/Utility/Helpers';
import {ClipboardData} from '@/Utility/Clipboard/ClipboardData';

/**
 * Wrapper around the browser's clipboard API.
 *
 * In some browsers the methods provided here will only work within user
 * gesture event handlers, when the user has recently interacted with the page
 * and/or when the page is focused.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#security_considerations
 *
 * @internal
 */
export abstract class Clipboard {

    /**
     * Read previously copied ClipboardData from the given ClipboardEvent.
     * The browser's default behaviour will be suppressed if a valid ClipboardData is detected.
     */
    static readClipboardDataFromEvent(e: ClipboardEvent | null): ClipboardData | null {
        if (!e?.clipboardData) {
            return null;
        }

        try {
            const text = e.clipboardData.getData('text/plain');
            const dataObject = ClipboardData.deserialize(text);

            // Prevent browser behaviour if it's a valid ClipboardData object,
            // so it won't be inserted into inputs or textareas:
            e.preventDefault();

            return dataObject;
        } catch (_) {
            return null;
        }
    }

    /**
     * Write data to clipboard. If it is not yet an instance of ClipboardData,
     * it will be wrapped in its data structure.
     *
     * A toast will be shown.
     */
    static async writeClipboardData(data: ClipboardData | any): Promise<void> {
        // Convert to ClipboardData object
        const clipboardData = (data instanceof ClipboardData)
            ? data
            : (
                (typeof data.toClipboardData === 'function')
                    ? data.toClipboardData()
                    : new ClipboardData(data)
            );

        // Serialize and write to clipboard
        await Clipboard.writeString(
            clipboardData.serialize(),
            data.clipboardTitle || null
        );
    }

    /**
     * Write string to clipboard. The given string will be copied as plain text and
     * not be wrapped in any data structure.
     *
     * A toast will be shown.
     */
    static async writeString(clipboardString: string, displayTitle: string | null = null): Promise<void> {

        const displayString = displayTitle
            ? trans('labels.copied_to_clipboard', { placeholder: displayTitle }, false)
            : trans('labels.copied_to_clipboard_default');

        await navigator.clipboard.writeText(clipboardString);
        window.toast(displayString, { timeout: 1500, closeButton: false });
    }
}
