import type {Feature} from '@/Models/Features/Feature';
import {ClipboardData} from '@/Utility/Clipboard';

/**
 * Abstract base class for all objects being created from a unit's data (e.g. all child data nodes on a UnitData model)
 */
export default abstract class AbstractDataObject
{
    public parent: AbstractDataObject | null;

    protected constructor(parent: AbstractDataObject | null = null)
    {
        this.parent = parent;

        // Hidden parent attribute (not enumerable which makes it "hidden" so it doesn't get stored in the database when sent to the API):
        Object.defineProperty(this,
            'parent',
            {
                configurable: false,
                enumerable: false,
                writable: true,
            }
        );
    }

    get constructorName(): string
    {
        return this.constructor.constructorName;
    }

    get clipboardTitle(): string
    {
        return this.constructorName;
    }

    /**
     * Entitlements (or features) needed to use this piece of data inside a unit.
     * Empty if no entitlement is needed (default).
     */
    get entitlementsNeeded(): Feature[]
    {
        return [];
    }

    /**
     * Is this object global? (e.g. not part of a TrainingScene)
     */
    get isGlobal(): boolean
    {
        return this.getParentByConstructorName('TrainingScene') === null;
    }

    get isValid(): boolean
    {
        // Force implementation on inherited classes:
        if (!this.constructor.prototype.hasOwnProperty('isValid'))
        {
            throw new Error(`AbstractDataObject->isValid(): Subclass "${this.constructor.name}" must implement its own getter for isValid()`);
        }
        return true;
    }

    /**
     * Clean up data (e.g. remove forbidden nested commands or invalid targets and values)
     * Returns true if anything was changed, false otherwise
     */
    cleanUpData(): boolean
    {
        // Force implementation on inherited classes:
        if (!this.constructor.prototype.hasOwnProperty('cleanUpData'))
        {
            throw new Error(`AbstractDataObject->cleanUpData(): Subclass "${this.constructor.name}" must implement cleanUpData()`);
        }
        return false;
    }

    toClipboardData(): ClipboardData
    {
        return new ClipboardData({
            ...this,
            constructorName: this.constructorName,
        });
    }

    /**
     * @returns nearest parent of this object of the given class (type safe)
     * @example
     * mySceneObject.getParent(Unit)?.latest_revision_uid
     */
    getParent<T extends AbstractDataObject>(parentClass: new(...args: any[]) => T): T | null {
        if (!this.parent) {
            return null;
        }

        if (this.parent instanceof parentClass) {
            return this.parent;
        }

        return this.parent.getParent(parentClass);
    }

    /**
     * Use this over `getParent()` to avoid circular dependencies in typescript imports.
     * @returns nearest parent of this object with the given constructor name
     */
    private getParentByConstructorName<T extends AbstractDataObject>(constructorName: string): T | null {
        if (!this.parent) {
            return null;
        }

        if (this.parent.constructorName === constructorName) {
            return this.parent as T;
        }

        return this.parent.getParentByConstructorName<T>(constructorName);
    }

    get parents(): AbstractDataObject[]
    {
        return this.parent ? [this.parent].concat(this.parent.parents || []) : [];
    }

    /**
     * Define the constructor's class name since we can't use constructor.name
     * because of Javascript minifier and cannot use instanceof because of circular dependencies
     */
    static get constructorName(): string
    {
        // Force implementation on inherited classes:
        if (!this.hasOwnProperty('constructorName') && !this.prototype.hasOwnProperty('constructorName') && !(this instanceof AbstractDataObject))
        {
            throw new Error(`AbstractDataObject->constructorName(): Subclass "${this.name}" must implement static getter "constructorName()"`);
        }
        return 'AbstractDataObject';
    }

    static createFromAttributes(attributes: {}): any
    {
        // @NOTE: This works but since we have models with different classes depending on their "type" attribute we instead force subclass implementation for now!
        //return new (this)(...arguments);

        // Force implementation on inherited classes:
        if (!this.hasOwnProperty('createFromAttributes') && !this.prototype.hasOwnProperty('createFromAttributes') && !(this instanceof AbstractDataObject))
        {
            throw new Error(`AbstractDataObject->createFromAttributes(): Subclass "${this.name}" must implement static method "createFromAttributes()"`);
        }
    }

    static fromClipboardData(clipboardData: ClipboardData): AbstractDataObject | any | null
    {
        if (!clipboardData.isInstanceOf(this))
        {
            return null;
        }
        try
        {
            return this.createFromAttributes(clipboardData.data);
        }
        catch (exception)
        {
            console.error(`${this.constructorName}->fromClipboardData(): Unable to parse data.`, exception);
            return null;
        }
    }
}
