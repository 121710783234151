import {trans} from '@/Utility/Helpers';
import {getInitial} from '@/Utility/Helpers/getInitial';

export default class UserNameOnly {

    static get constructorName(): string {
        return 'UserNameOnly';
    }

    public readonly uid: string;
    public readonly firstname: string | null;
    public readonly lastname: string | null;

    constructor(attributes: any = {}) {
        this.uid = attributes.uid;
        this.firstname = attributes.firstname || null;
        this.lastname = attributes.lastname || null;
    }

    get constructorName(): string {
        return UserNameOnly.constructorName;
    }

    get fullName(): string {
        const name: string = `${this.firstname || ''} ${this.lastname || ''}`.trim();
        return (name !== '') ? name : trans('labels.unknown_user');
    }

    get initials(): string {
        return getInitial(this.firstname) + getInitial(this.lastname);
    }
}
