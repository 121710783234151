<template>
    <Modal
        id="dialog-unit-links"
        ref="modal"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.unit_links.title')"
        :user-closable="true"
        event-type="MODAL_UNIT_LINKS"
    >
        <table class="unit-links-table">
            <thead class="unit-links-table-header">
                <tr>
                    <th class="name">
                        {{ trans('modals.unit_links.table.name') }}
                    </th>
                    <th class="start-date">
                        {{ trans('modals.unit_links.table.start_date') }}
                    </th>
                    <th class="end-date">
                        {{ trans('modals.unit_links.table.end_date') }}
                    </th>
                    <th class="publisher">
                        {{ trans('modals.unit_links.table.publisher') }}
                    </th>
                    <th class="password">
                        {{ trans('modals.unit_links.table.password') }}
                    </th>
                    <th class="actions" />
                </tr>

            </thead>
            <tbody class="unit-links-table-body">
                <ModalUnitLinksTableRow
                    v-for="unitLink in unitLinks"
                    :key="'unit-link-key-'+unitLink.uid"
                    :unit="unit"
                    :unit-link="unitLink"
                    @click-delete="onClickUnitLinkRemove"
                    @click-edit="onClickUnitLinkEdit"
                />
            </tbody>
        </table>
        <template #buttons>
            <ButtonSecondary
                caption="modals.unit_links.buttons.close"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                v-if="canCreate"
                caption="modals.unit_links.buttons.new"
                class="btn-apply"
                icon="icon_add"
                @trigger="showCreateUnitLinksModal"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import Modal from '@/Vue/Modals/Modal.vue';
import Unit from '@/Models/Unit/Unit';
import {defineComponent, inject} from 'vue';
import {trans} from '@/Utility/Helpers';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import UnitLink from '@/Models/Unit/UnitLink';
import ModalUnitLinksTableRow from '@/Vue/Modals/UnitLinks/ModalUnitLinksTableRow.vue';
import {unitLinkServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import EventType from '@/Utility/EventType';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({
    components: {
        ModalUnitLinksTableRow,
        ButtonPrimary,
        ButtonSecondary,
        Modal
    },

    props: {
        unit: {
            type: Unit,
            required: true,
        },
    },

    data() {
        return {
            unitLinkService: inject(unitLinkServiceKey)!,

            events: new Map([
                [EventType.MODAL_UNIT_LINKS_DELETE_APPLY, this.onDeleteUnitLinkApply],
            ]),
        };
    },

    computed: {
        unitLinks() {
            return this.unit?.unit_links ?? [];
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },

        canCreate() {
            return this.$gate.allows(
                Permission.ability(Permission.UnitLinksCreate()),
                UnitLink.constructorName,
                this.unit
            );
        },
    },

    mounted() {
        // Add global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });
    },

    beforeUnmount() {
        // Remove global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    methods: {
        trans,

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            this.modal.hide();
        },

        onClickUnitLinkRemove(unitLink: UnitLink) {
            this.$globalEvents.emit(EventType.MODAL_UNIT_LINKS_DELETE_SHOW, unitLink);
        },

        onDeleteUnitLinkApply(unitLink: UnitLink) {
            this.unitLinkService
                ?.deleteUnitLink(unitLink)
                .then(unitLink => {
                    this.removeUnitLink(unitLink);
                    this.$globalEvents.emit(EventType.MODAL_UNIT_LINKS_DELETE_HIDE);
                })
                .catch(this.onErrorApi);
        },

        onClickUnitLinkEdit(unitLink: UnitLink) {
            this.$globalEvents.emit(EventType.MODAL_UNIT_LINKS_EDIT_SHOW, unitLink);
        },

        /**
         * Remove a specific unit from the list
         */
        removeUnitLink(unitLink: UnitLink) {
            const index = this.unit.unit_links.findIndex(t => t.uid === unitLink.uid);
            // Remove the unit from the cached list
            if (index >= 0) {
                // eslint-disable-next-line vue/no-mutating-props
                this.unit.unit_links.splice(index, 1);
            }

            return this;
        },

        showCreateUnitLinksModal() {
            this.$globalEvents.emit(EventType.MODAL_UNIT_LINKS_CREATE_SHOW);
        },

        /**
         * Error handler for API errors
         *
         * @param {String} error
         */
        onErrorApi(error: string) {
            this.$root!.showErrorDialog(error);
            return this;
        },

    }
});
</script>

<style scoped>
#dialog-unit-links {
    width: 800px;

    .buttons {
        .btn {
            flex-grow: 0 !important;
        }
    }

    table {
        flex: 1;
        display: grid;
        margin-top: 12px;
        border-collapse: collapse;

        /* These are just initial values which are overriden using JavaScript when a column is resized */
        grid-template-columns:
            minmax(150px, 3fr)
            minmax(100px, 1fr)
            minmax(100px, 1fr)
            minmax(150px,1.30fr)
            minmax(70px,.5fr)
            minmax(60px, 0.5fr);
    }

    thead,
    tbody,
    tr {
        display: contents;
    }

    th,
    :deep(td) {
        display: flex;
        min-width: 0;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

    }

    th {
        background: var(--background-color-light);
        font-family: var(--font-family-condensed-demibold);
        font-weight: normal;
        margin-bottom: 8px;
    }

    th.name {
        padding-left: 8px;
    }

    th.password {
        justify-content: center;
    }

    th:last-child {
        border: 0;
    }

    :deep(.unit-links-table-row) {
        td {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-top: 4px;
            padding-bottom: 4px;
            padding-right: 10px;
        }

        td.name {
            padding-right: 20px;
        }

        td.name .btn {
            margin-right: 6px;
            margin-left: 4px;
        }

        td.publisher {
            padding-right: 10px;
            text-overflow: ellipsis;
        }

        td.password {
            justify-content: center;
            padding-right: 0;
        }

        td.actions {
            justify-content: center;
            padding-right: 0;
        }
    }
}
</style>
