<template>
    <Modal
        ref="modal"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.duplicate_course.title')"
        :user-closable="true"
        event-type="MODAL_DUPLICATE_COURSE"
        @show="onShow"
    >
        <!-- eslint-disable vue/no-v-html -->
        <p
            v-if="course"
            class="description"
            v-html="trans('modals.duplicate_course.description', {title: course.title})"
        />
        <!-- eslint-enable vue/no-v-html -->
        <TextInput
            :error-msg="trans('errors.course.title')"
            :initial-value="newTitle"
            :maxlength="50"
            :placeholder="trans('courses.create.course_title_placeholder')"
            :required="true"
            @change="onChangeTitle"
        />
        <hr v-if="canDuplicateToForeignTenant">
        <div v-if="canDuplicateToForeignTenant" class="section-tenant-selection">
            <h3>{{ trans('modals.duplicate_course.select_team_caption') }}</h3>
            <TenantListItem
                :tenant="targetTenant"
                class="tenant-row"
                @select="onClickSelectTenant"
            />
        </div>
        <template #buttons>
            <ButtonSecondary
                caption="modals.duplicate_course.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                :disabled="!isValid"
                caption="modals.duplicate_course.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import {defineComponent} from 'vue';
import Modal from '@/Vue/Modals/Modal.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import {Permission} from '@/Models/User/Permission';
import type Tenant from '@/Models/Tenant/Tenant';
import TenantListItem from '@/Vue/Modals/SelectTenant/TenantListItem.vue';
import type Course from '@/Models/Course/Course';

export default defineComponent({
    components: {
        TenantListItem,
        TextInput,
        ButtonPrimary,
        ButtonSecondary,
        Modal
    },

    data() {
        const currentTenant = window.currentUser!.tenant!;

        return {
            /**
             * Reference to the Course for which this dialog is being shown
             */
            course: null as Course | null,

            /**
             * Title of the duplicated course
             */
            newTitle: '',

            /**
             * Uid of the current tenant the user is working in.
             */
            currentTenant: currentTenant,

            /**
             * Tenant to duplicate this course into. Defaults to current tenant.
             */
            targetTenant: currentTenant,
        };
    },

    computed: {
        canDuplicateToForeignTenant() {
            return this.$gate.allows(Permission.ability(Permission.CoursesDuplicateToForeignTenant()), this.course);
        },

        isValid() {
            return this.newTitle.length > 0 && this.newTitle.length <= 50;
        },

        isTargetTenantCurrentTenant() {
            return this.targetTenant.uid === this.currentTenant.uid;
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },
    },

    methods: {
        trans,

        onShow(course: Course) {
            this.course = course;

            this.newTitle = course.title + ' ' + trans('modals.duplicate_course.copy_suffix');
            this.targetTenant = this.currentTenant;
        },

        onTenantChanged(tenant: Tenant) {
            this.targetTenant = tenant;
        },

        onClickSelectTenant() {
            this.$globalEvents.emit(EventType.MODAL_SELECT_TENANT_SHOW, this.onTenantChanged);
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            if (!this.isValid) {
                return;
            }

            this.modal.hide();

            this.$globalEvents.emit(
                EventType.MODAL_DUPLICATE_COURSE_APPLY,
                this.course,
                this.newTitle,
                this.targetTenant.uid,
            );
        },

        onChangeTitle(newTitle: string) {
            this.newTitle = newTitle;
        },
    }
});
</script>

<style lang="scss" scoped>

dialog {
    width: 440px;

    .section-tenant-selection {
        list-style-type: none;

        .tenant-row {
            margin: 0 -32px;
        }
    }
}

</style>
