import type {RouteName} from '@/Generated/routes';
import {get} from 'lodash';

/**
 * Route helper (ported from Laravel)
 *
 * @param {RouteName} routeName     // Name of the Laravel route (e.g. 'units.index')
 * @param {*} params                // Optional route parameters (e.g. {unit: '00000000-0000-0000-0000-000000000000'})
 * @returns {String}
 */
export function route(routeName: RouteName, params: any = null): string {
    let route = get(window.appRoutes, routeName) as string;

    if (route === undefined) {
        console.error('route() -> Unknown route: ' + routeName);
        throw new Error('route() -> Unknown route: ' + routeName);
    }

    // Replace route parameters:
    if (params !== null)
    {
        if (!(params instanceof Object))
        {
            console.error('route() -> Route parameters must be an object');
            throw new TypeError('route() -> Route parameters must be an object');
        }
        for (const index in params)
        {
            if (Object.prototype.hasOwnProperty.call(params, index))
            {
                route = route.replace('{' + index + '?}', params[index]);
                route = route.replace('{' + index + '}', params[index]);
            }
        }
    }
    // Replace optional parameters:
    route = route.replace(/\{.*?\?\}/g, '');
    // Throw an error for any required missing parameters:
    const requiredParams: RegExpMatchArray | null = route.match(/(\{.*?\})/g);
    if (requiredParams !== null)
    {
        console.error('route() -> Missing required parameters: ' + requiredParams.join(', '));
        throw new Error('route() -> Missing required parameters: ' + requiredParams.join(', '));
    }
    return route;
}
