<template>
    <tr>
        <td class="column-name">
            {{ feature.name }}
        </td>
        <td>
            {{ description }}
        </td>
        <td class="column-created-at">
            {{ createdAt }}
        </td>
        <td class="column-remove">
            <ButtonCircular
                v-tooltip="'labels.remove'"
                :disabled="!canDelete"
                class="small"
                icon="icon_delete"
                @trigger="onRemove"
            />
        </td>
    </tr>
</template>

<script lang="ts">
import moment from 'moment';
import LocalFeature from '@/Models/Features/Local/LocalFeature';
import {Feature} from '@/Models/Features/Feature';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'LocalFeatureListItem',

    components: {
        ButtonCircular
    },

    props: {
        feature: {
            type: LocalFeature,
            required: true,
        },
        canDelete: {
            type: Boolean
        }
    },

    emits: [
        'click-remove',
    ],

    computed: {
        description() {
            return Feature.getDescription(this.feature.name);
        },

        createdAt() {
            return moment(this.feature.created_at).format('DD MMM YYYY, HH:mm');
        },
    },

    methods: {

        onRemove() {
            this.$emit('click-remove', this.feature);
        }

    },
});
</script>

<style lang="css" scoped>

.column-name {
    padding: 8px 0;
}

.column-created-at {
    white-space: nowrap;
    padding-right: 32px;
}

</style>
