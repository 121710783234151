<template>
    <Modal
        id="dialog-unit-links-create"
        ref="modal"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.unit_links_create.title')"
        :user-closable="true"
        event-type="MODAL_UNIT_LINKS_CREATE"
    >
        <div class="url">
            <TextInput
                :model="form"
                :placeholder="trans('modals.unit_links_create.placeholders.url')"
                :read-only="true"
                class="no-wrap"
                icon="icon_copy"
                property="url"
            />
            <ButtonCircular
                v-tooltip="'labels.copy'"
                class="small"
                disabled="disabled"
                icon="icon_copy"
            />
        </div>

        <TextInput
            :label="trans('modals.unit_links_create.labels.name')"
            :maxlength="50"
            :model="form"
            :placeholder="trans('modals.unit_links_create.placeholders.name')"
            class="no-wrap"
            property="name"
            autocomplete="new-password"
        />

        <div :class="{ 'is-invalid' : errors?.valid_from }" class="date-picker-wrapper has-label no-wrap start-date">
            <label for="valid_from">{{ trans('modals.unit_links_create.labels.start_date') }}</label>
            <input
                id="valid_from"
                ref="startDate"
                v-model="form.valid_from"
                v-tooltip="startDateTooltip"
                name="valid_from"
                type="datetime-local"
            >
        </div>
        <div v-if="errors?.valid_from" class="invalid-feedback" role="alert">
            <strong>{{ errors.valid_from }}</strong>
        </div>

        <div
            :class="{ 'checkbox-disabled' : !form.enable_end_date, 'is-invalid' : errors?.expires_at }"
            class="date-picker-wrapper has-label no-wrap"
        >

            <Checkbox
                :model="form"
                class="no-wrap"
                layout="checkbox-left"
                property="enable_end_date"
                @click.stop
            />
            <label for="expires_at">{{ trans('modals.unit_links_create.labels.end_date') }}</label>
            <input
                id="expires_at"
                ref="endDate"
                v-model="form.expires_at"
                v-tooltip="endDateTooltip"
                :disabled="!form.enable_end_date"
                class="end-date"
                name="expires_at"
                type="datetime-local"
                @change="validate"
            >
        </div>
        <div v-if="errors?.expires_at" class="invalid-feedback" role="alert">
            <strong>{{ errors.expires_at }}</strong>
        </div>

        <div
            class="password-wrapper has-label no-wrap"
            :class="{ 'checkbox-disabled' : !form.enable_password, 'is-invalid' : errors?.password }"
        >
            <Checkbox
                class="no-wrap"
                :model="form"
                property="enable_password"
                layout="checkbox-left"
                @click.stop
            />
            <PasswordInput
                :key="key"
                :disabled="!form.enable_password"
                class="no-wrap"
                :model="form"
                :property="passwordProperty"
                :label="trans('modals.unit_links_create.labels.password')"
                :placeholder="passwordPlaceholder"
                :maxlength="30"
                :required="true"
                autocomplete="new-password"
            />
        </div>


        <template #buttons>
            <ButtonSecondary
                :disabled="isSaving"
                caption="modals.unit_links_create.buttons.close"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                :disabled="hasErrors || isSaving"
                caption="modals.unit_links_create.buttons.save"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import Modal from '@/Vue/Modals/Modal.vue';
import Unit from '@/Models/Unit/Unit';
import {defineComponent, inject} from 'vue';
import {shortId, trans} from '@/Utility/Helpers';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {unitLinkServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import TextInput from '@/Vue/Common/TextInput.vue';
import moment from 'moment';
import Checkbox from '@/Vue/Common/Checkbox.vue';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import {cloneDeep, debounce} from 'lodash';
import PasswordInput from '@/Vue/Common/PasswordInput.vue';

export default defineComponent({
    components: {
        PasswordInput,
        ButtonCircular,
        Checkbox,
        TextInput,
        ButtonPrimary,
        ButtonSecondary,
        Modal
    },

    props: {
        unit: {
            type: Unit,
            required: true,
        },
    },

    data() {
        return {
            unitLinkService: inject(unitLinkServiceKey)!,
            key: shortId(),

            defaults: {
                form: {
                    name: null,
                    valid_from: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
                    enable_end_date: false,
                    expires_at: moment().add(30, 'days').endOf('day').format('YYYY-MM-DDTHH:mm'),
                    enable_password: false,
                    password: null,
                    password_empty: null,
                }
            },

            form: {
                name: null,
                valid_from: moment().startOf('day').format('YYYY-MM-DDTHH:mm'),
                enable_end_date: false,
                expires_at: moment().add(30, 'days').endOf('day').format('YYYY-MM-DDTHH:mm'),
                enable_password: false,
                password: null,
            },

            errors: {} as any,
        };
    },

    computed: {

        passwordProperty() {
            return this.form.enable_password
                ? 'password'
                : 'password_empty';
        },

        passwordPlaceholder() {
            return this.form.enable_password
                ? trans('modals.unit_links_create.placeholders.password')
                : trans('modals.unit_links_create.placeholders.password_disabled');
        },

        startDateTooltip() {
            return moment(this.form.valid_from).isValid() ? moment(this.form.valid_from).toISOString() : null;
        },

        endDateTooltip() {
            return this.form.enable_end_date && moment(this.form.expires_at).isValid() ?
                moment(this.form.expires_at).toISOString() :
                null;
        },

        unitLinks() {
            return this.unit?.unit_links ?? [];
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },

        isSaving() {
            return this.unitLinkService.isSaving;
        },
    },

    watch: {
        'form.expires_at': function() {
            this.validate();
        },
        'form.valid_from': function() {
            this.validate();
        },
        'form.enable_end_date': function() {
            this.validate();
        },
        'form.enable_password': function() {
            this.key = shortId();
        }
    },

    mounted() {
        this.reset();
    },

    methods: {
        trans,

        validateDateTime(name: string) {
            delete this.errors[name];

            const selectedDateTime = moment(this.form[name], moment.ISO_8601, true);

            if (!selectedDateTime.isValid()) {
                this.errors[name] = trans('modals.unit_links_create.errors.invalid_date');
                return;
            }

        },

        validate() {
            this.errors = new Object({});

            this.validateDateTime('valid_from');

            if (this.form.enable_end_date) {
                this.validateDateTime('expires_at');
            }
        },

        createTenantUnitLink() {

            this.validate();

            if (this.hasErrors) {
                return;
            }

            let unitLinkData = {
                tenant_uid: window.currentUser!.tenant!.uid,
                unit_uid: this.unit.uid,
                name: this.form.name !== '' ? this.form.name : null,
                expires_at: this.form.enable_end_date && this.form.expires_at !== '' ?
                    (new Date(this.form.expires_at)).toISOString() :
                    null,
                valid_from: (new Date(this.form.valid_from)).toISOString(),
                password: this.form.enable_password && this.form.password !== '' ?
                    this.form.password :
                    null,
            };

            this.unitLinkService
                .createUnitLink(
                    unitLinkData
                )
                .then(unitLink => {
                    this.unit.unit_links.push(unitLink);
                    this.reset();
                    this.$toast.success(trans('modals.unit_links_create.messages.created'));
                    this.modal.hide();
                })
                .catch(this.onErrorApi);
        },

        reset() {
            this.form = cloneDeep(this.defaults.form);
            this.errors = new Object({});
        },

        onClickCancel() {
            this.reset();
            this.modal.cancel();
        },

        onClickApply: debounce(function(this: any) {
            this.createTenantUnitLink();
        }, 500),

        /**
         * Error handler for API errors
         *
         * @param {String} error
         */
        onErrorApi(error: string) {
            this.$root!.showErrorDialog(error);
            return this;
        },

    }
});
</script>

<style scoped>

</style>
