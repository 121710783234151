import {snakeCase} from 'lodash';
import {trans} from "@/Utility/Helpers/trans";

export enum Feature {

    EntitlementAssetProviderSketchfab,
    EntitlementAuthScim,
    EntitlementCharacterAiExtensions,
    EntitlementDevicetypeWebapp,
    EntitlementHelperAiExtensions,
    EntitlementOnboardingTourDisabled,
    EntitlementPublicUnits,
    EntitlementStatisticsPage,
    EntitlementSupportChat,
    EntitlementTrialBannerDisabled,
    EntitlementUserguiding,
    FeatureAiccSupport,
    FeatureApphelpDisplayUnpublished,
    FeatureAssetBundles,
    FeatureBetaBannerDisabled,
    FeatureForwardLearningRecordsWithoutDelay,
    FeatureInviteUsersToTeam,
    FeatureManagedSessions,
    FeatureSharedTeamAssets,
    FeatureTelescopeLogEverything,
    FeatureUploadCharacters,
    FeatureWebappPlayerDev,
}

export namespace Feature {
    export function getName(feature: Feature | string): string {
        return typeof feature === 'string' ? feature : snakeCase(Feature[feature]);
    }

    export function getTitle(feature: Feature | string): string {
        const name = getName(feature);
        return trans('features.title.' + name);
    }

    export function getDescription(feature: Feature | string): string {
        const name = getName(feature);
        return trans('features.description.' + name);
    }

    export function getNotAvailableText(feature: Feature): string {
        return trans('features.not_available.single_text', {feature: Feature.getTitle(feature)})
    }

    export function getNotAvailableTooltip(feature: Feature): string {
        return trans('features.not_available.single_tooltip', {feature: Feature.getTitle(feature)})
    }

    export function all(): Feature[] {
        return Object.keys(Feature)
            .map(index => Number(index))
            .filter(index => !isNaN(index));
    }

}
