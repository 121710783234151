<template>
    <nav
        v-if="numberOfPages > 1 || !hideForSinglePage"
        class="pagination"
    >

        <template v-for="button in buttons" :key="button">

            <!-- eslint-disable vue/no-v-html -->
            <button
                :class="{ active: button.active }"
                :disabled="button.disabled || button.active"
                class="pagination-page-link"
                @click="onPageClick(button.pageNumber)"
                v-html="button.caption"
            />
            <!-- eslint-enable vue/no-v-html -->

        </template>

    </nav>
</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers';
import {defineComponent} from 'vue';

type Button = {
    pageNumber: number;
    caption: string;
    disabled: boolean;
    active: boolean;
}

export default defineComponent(
    {
        name: 'Pagination',

        props: {
            /**
             * Currently selected page; Index is based on 1.
             */
            currentPage: {
                type: Number,
                default: 1
            },

            /**
             * Total number of pages to display.
             */
            numberOfPages: {
                type: Number,
                default: 1
            },

            /**
             * Minimum number of pages that are displayed
             * each before and after the current page
             * without ellipsis.
             */
            spaceAroundCurrentPage: {
                type: Number,
                default: 2
            },

            hideForSinglePage: {
                type: Boolean,
                default: true
            }
        },

        emits: [
            'click',
        ],

        computed: {

            buttons(): Button[] {
                const buttons: Button[] = [];

                // previous
                buttons.push({
                    pageNumber: this.currentPage - 1,
                    caption: '&lt;',
                    disabled: this.currentPage <= 1,
                    active: false,
                });

                // first page
                buttons.push(this.getPageButton(1));

                const minSpaceFromEnds = this.spaceAroundCurrentPage * 2 + 2 + 1;
                let startPage = Math.max(
                    2,
                    Math.min(this.currentPage - this.spaceAroundCurrentPage, this.numberOfPages + 1 - minSpaceFromEnds)
                );
                let endPage = Math.min(
                    this.numberOfPages - 1,
                    Math.max(this.currentPage + this.spaceAroundCurrentPage, minSpaceFromEnds)
                );

                if (startPage === 3) {
                    // do display page instead of ellipsis
                    startPage = 2;
                }
                if (endPage === this.numberOfPages - 2) {
                    // do display page instead of ellipsis
                    endPage = this.numberOfPages - 1;
                }

                // optional space
                if (startPage > 2) {
                    buttons.push(this.getEllipsisButton());
                }

                // pages around current
                for (let page = startPage; page <= endPage; page++) {
                    buttons.push(this.getPageButton(page));
                }

                // optional space
                if (endPage < this.numberOfPages - 1) {
                    buttons.push(this.getEllipsisButton());
                }

                // last page
                if (this.numberOfPages > 1) {
                    buttons.push(this.getPageButton(this.numberOfPages));
                }

                // next
                buttons.push({
                    pageNumber: this.currentPage + 1,
                    caption: '&gt;',
                    disabled: this.currentPage >= this.numberOfPages,
                    active: false,
                });

                return buttons;
            },

        },

        methods: {
            trans,

            /**
             * Called when a page link has been clicked by the user.
             */
            onPageClick(page: number) {
                this.$emit('click', page);
            },

            getPageButton(page: number): Button {
                return {
                    pageNumber: page,
                    caption: page.toString(),
                    disabled: false,
                    active: this.currentPage === page,
                };
            },

            getEllipsisButton(): Button {
                return {
                    pageNumber: 0,
                    caption: '…',
                    disabled: true,
                    active: false,
                };
            },
        },
    })
;
</script>

<style lang="css" scoped>
.pagination {
    display: inline-flex;
    border: 2px solid var(--color-white);
    border-radius: 4px;

    .pagination-page-link {
        border-width: 0;
        background: none;
        border-radius: 4px;
        color: var(--font-color-dark);
        font-family: var(--font-family-condensed-demibold);
        padding: 12px 24px;

        &.active {
            color: var(--color-primary);
            background-color: var(--color-white);
        }

        &:first-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:last-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:not(:disabled) {
            &:hover,
            &:focus-visible {
                color: var(--color-primary-hover);
            }
        }
    }
}
</style>
