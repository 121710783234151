<template>
    <ModalApplyCancel
        ref="modal"
        :apply-text="trans('labels.delete')"
        :description="description"
        :title="trans('tenants.delete.modals.confirm.title')"
        event-type="MODAL_DELETE_TENANT"
        @show="onShow"
    />
</template>

<script lang="ts">

import {permission, trans} from '@/Utility/Helpers';
import {defineComponent} from 'vue';
import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
import type Tenant from '@/Models/Tenant/Tenant';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({
    components: {
        ModalApplyCancel,
    },

    data() {
        return {
            description: null as string | null,
        };
    },

    methods: {
        trans,

        onShow(tenant: Tenant) {
            const transKey = permission(Permission.TenantsDisplayUid()) ?
                'tenants.delete.modals.confirm.description_uid' :
                'tenants.delete.modals.confirm.description';

            this.description = trans(transKey, {
                'name': tenant.name,
                'uid': tenant.uid,
            });
        }
    }
});
</script>

<style lang="css" scoped>

</style>
