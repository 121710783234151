<template>
    <div v-shortcuts.stop :class="cssClasses" :title="getTitleAttributeFromText">

        <!-- Label -->
        <label v-if="label" :for="uid">{{ label }}</label>

        <!-- Input -->
        <div class="input-wrapper">
            <input
                v-bind="attributes"
                ref="domElement"
                :readonly="readOnly"
                :type="inputType"
            >

            <ButtonCircular
                v-if="buttonType === 'reveal'"
                v-tooltip="'labels.copy'"
                class="small"
                :icon="revealIcon"
                @trigger="onClickReveal"
            />
            <ButtonCircular
                v-if="buttonType === 'copy'"
                v-tooltip="'labels.copy'"
                class="small"
                icon="icon_copy"
                @trigger="onClickCopy"
            />
        </div>


        <!-- Error messages -->
        <span v-if="validationErrors && validationErrors.length > 0" class="error-msg">
            <strong v-html="validationErrors[0]" />
        </span>
        <span v-else-if="errorMsg" class="error-msg" v-html="errorMsg" />

    </div>
</template>

<script lang="ts">
import {defineComponent, mergeProps} from 'vue';
import {shortId} from '@/Utility/Helpers';
import KeyboardKey from '@/Utility/KeyboardKey';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import {Clipboard} from '@/Utility/Clipboard';

export default defineComponent({
    components: { ButtonCircular },
    inheritAttrs: false,
    props: {
        initialValue: {         // Initial text (either use this or model+property!)
            type: String,
            default: ''
        },
        model: {                // Associated model reference
            type: Object,
            default: null
        },
        property: {             // Property name from the associated model that should be modified
            type: String,
            default: null
        },
        disabled: {             // Disabled state
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        required: {             // Required state
            type: Boolean,
            default: false
        },
        label: {                // Optional label text
            type: String,
            default: null
        },
        type: {                 // Type (e.g. 'text', 'email', 'password', 'url', 'search')
            type: String,
            default: 'password'
        },
        maxlength: {            // Maximum string length
            type: Number,
            default: null
        },
        minlength: {            // Minimum string length
            type: Number,
            default: null
        },
        name: {                 // Form name
            type: String,
            default: null,
        },
        placeholder: {          // Placeholder text
            type: String,
            default: null
        },
        errorMsg: {             // Error message text
            type: String,
            default: null
        },
        restrictKeys: {         // List of KeyboardKeys to restrict input
            type: Array,
            default() {
                return [];
            }
        },
        minLengthForAddingTooltip: {    // Minimum number of characters before showing a tooltip on the component
            type: Number,
            default: null
        },
        validationErrors: {
            type: Array,
            default() {
                return [];
            }
        },
        delayChangeOnInput: {     // Whether to use a delay when triggering change event after each input event
            type: Boolean,
            default: false
        },
        blurOnEnterKey: {   // Whether to trigger a blur event when using the [ENTER] key (or CTRL+ENTER for textareas)
            type: Boolean,
            default: true
        },
        buttonType: {
            type: String,
            default: 'reveal',
        }

    },
    emits: [
        'focus',
        'blur',
        'change',
        'cancel',
        'key-enter',
    ],
    data() {
        return {
            uid: shortId('passwordinput'),            // A unique identifier for HTML id="" attribute
            text: '',                                   // The edited text
            previousValue: null as string | null,                        // Previous text value when focusing on the field
            errors: {},                                 // Validation errors
            inputTimer: null as any | null,                           // Timeout helper for triggering validation on input events
            hadFocusOnce: false,                        // Will be set to true onChange / onBlur, so we can ignore validation before the element was focused at least once
            shortcuts: new Map([
                ['Duplicate.prevent', null],            // Prevent browser behaviour
                ['Publish.prevent', null],              // Prevent browser behaviour (printing dialog)
                ['Replace.prevent', null],              // Prevent browser behaviour
                ['Save.prevent', null],                 // Prevent browser behaviour
                ['Any', null]                           // Allow any other shortcut but stop propagation
            ]),
            inputType: '',
        };
    },
    computed: {

        /**
         * Additional attributes (e.g. for validation)
         */
        attributes(): object {

            const attrs = {
                id: this.uid,
                // type: this.inputType,
            };

            // Disabled
            if (this.disabled) {
                attrs.disabled = true;
            } else {
                attrs.onKeydown = this.onKeyDown;
                attrs.onInput = this.onInput;
                attrs.onChange = this.onChange;
                attrs.onFocus = this.onFocus;
                attrs.onBlur = this.onBlur;
            }

            // Set initial value on the input field
            attrs.value = this.text;

            // Maxlength
            if (this.maxlength !== null) {
                attrs.maxlength = this.maxlength;
            }

            // Minlength
            if (this.minlength !== null) {
                attrs.minlength = this.minlength;
            }

            // Name
            if (this.name !== null) {
                attrs.name = this.name;
            }

            // Placeholder
            if (this.placeholder !== null) {
                attrs.placeholder = this.placeholder;
            }

            // Required
            if (this.required) {
                attrs.required = 'required';
            }

            if (this.disabled) {
                attrs.disabled = true;
            }

            const mergedProps = mergeProps(attrs, this.$attrs);

            // Only allow CSS classes to be set on the root component
            delete mergedProps.class;

            return mergedProps;
        },

        /**
         * CSS classes for the checkbox
         *
         * @returns {String}
         */
        cssClasses() {
            const classes = [
                'passwordinput',
                'type-' + this.type
            ];

            // From attributes:
            if (this.$attrs.class) {
                classes.push(this.$attrs.class);
            }

            // Has label:
            if (this.label) {
                classes.push('has-label');
            }

            // Enabled/disabled state:
            classes.push(this.disabled ? 'disabled' : 'enabled');

            // Required state:
            if (this.required) {
                classes.push('required');
            }

            // Empty state:
            if (this.text === null || this.text.trim() === '') {
                classes.push('is-empty');
            }

            // Error state:
            if (this.hasErrors) {
                classes.push('error');
            }

            return classes.join(' ');
        },

        /**
         * Get the text for the title attribute
         */
        getTitleAttributeFromText(): string | undefined {

            if (this.type === 'password') {
                return undefined;
            }

            const limit = this.minLengthForAddingTooltip || 28;
            if (this.text.length > limit) {
                return this.text;
            } else if (this.$attrs.title) {
                return this.$attrs.title as string;
            }
            return undefined;
        },

        /**
         * Validation
         *
         * @returns {Boolean}
         */
        hasErrors(): boolean {
            // Only trigger this after the component is being mounted:
            if (this.$refs.domElement === null) {
                return false;
            }

            const trimmedValue = this.text.trim();

            // Required?
            if (this.required && trimmedValue === '' && this.hadFocusOnce) {
                this.errors.required = true;
            } else {
                delete this.errors.required;
            }

            // Maxlength?
            if (this.maxlength !== null && trimmedValue.length > this.maxlength) {
                this.errors.maxlength = true;
            } else {
                delete this.errors.maxlength;
            }

            // Minlength?
            if (this.minlength !== null && trimmedValue.length < this.minlength && this.hadFocusOnce) {
                this.errors.minlength = true;
            } else {
                delete this.errors.minlength;
            }

            return (Object.keys(this.errors).length > 0) || this.validationErrors.length > 0;
        },

        revealIcon() {
            if (this.inputType === 'password') {
                return 'icon_show_outline';
            }

            return 'icon_hide_outline';
        }
    },
    mounted() {

        this.inputType = this.type;

        // Check properties
        if (this.model !== null && this.property === null) {
            console.warn('PasswordInput->mounted(): Property :model="" is set but no property="" name is given.', this);
        }
        if (this.model !== null && this.initialValue !== '') {
            console.warn(
                'PasswordInput->mounted(): Both :model="" and :initial-value="" are set. You should use just one of them.',
                this
            );
        }

        // Set initial internal text value
        this.resetValue();
    },
    methods: {

        onClickCopy() {
            Clipboard.writeString(this.text);
        },

        onClickReveal() {
            if (this.type === 'password') {
                if (this.inputType === 'password') {
                    this.inputType = 'text';
                } else {
                    this.inputType = this.type;
                }
            }
        },

        focus() {
            this.$refs.domElement.focus();
        },

        /**
         * Focus handler
         *
         * @param {FocusEvent} e
         */
        onFocus(e) {
            this.previousValue = this.text;
            this.$emit('focus', e, this);
            return this;
        },

        /**
         * Blur handler
         *
         * @param {FocusEvent} e
         */
        onBlur(e) {
            this.hadFocusOnce = true;

            const trimmed = e.target.value.trim();

            // Reset to previous value if an input is required
            if (this.required && trimmed === '' && this.previousValue !== null) {
                this.text = this.previousValue;
                this.applyValue(e);

                // Remove whitespaces
            } else if (e.target.value !== trimmed || this.text !== trimmed) {
                this.text = trimmed;
            }

            // Reset caret and scroll position (unless user leaves browser window)
            if (e.relatedTarget !== null && ['text', 'password'].includes(this.type)) {
                this.resetCaretPosition();
            }

            // Pass the event, the value and the component to the parent
            this.$emit('blur', e, this);

            return this;
        },

        /**
         * Keydown handler
         *
         * @param {KeyboardEvent} e
         */
        onKeyDown(e) {
            if (this.disabled) {
                return this;
            }

            const key = KeyboardKey.findByEvent(e);

            // Prevent incorrect input
            if (this.restrictKeys.length >= 1) {
                const allowedKeyCodes = this.restrictKeys.map(k => k.code);
                if (key === null || (allowedKeyCodes.indexOf(key.code) === -1 && !e.metaKey && !e.ctrlKey && !e.altKey)) {
                    e.preventDefault();
                }
            }

            // Handle [Enter] key
            if (key === KeyboardKey.Enter && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                e.stopPropagation();
                if (this.blurOnEnterKey) {
                    this.$refs.domElement.blur();
                }
                this.$emit('key-enter', e, this);
            }

            // Handle [Escape] key
            if (key === KeyboardKey.Escape) {
                e.preventDefault();
                e.stopPropagation();
                e.target.value = this.previousValue;
                this.onChange(e);
                this.$refs.domElement.blur();
                this.$emit('cancel', e, this);
            }

            return this;
        },

        /**
         * Input handler (called between onKeyDown and onKeyUp)
         *
         * @param {InputEvent} e
         */
        onInput(e) {
            if (this.disabled) {
                return this;
            }

            // Use a timer to prevent VueJS from recomputing on every keystroke
            window.clearTimeout(this.inputTimer);
            this.inputTimer = window.setTimeout(() => {
                this.onChange(e);
            }, this.delayChangeOnInput ? 200 : 10);

            return this;
        },

        /**
         * Change handler
         *
         * @param {Event|KeyboardEvent} e
         */
        onChange(e) {
            if (this.disabled) {
                return this;
            }

            this.hadFocusOnce = true;

            // Clear the keyup timer
            window.clearTimeout(this.inputTimer);

            // Update internal value with the input/textarea value
            if (e.target.value.trim() === '') {
                e.target.value = '';
            } else if (/^\s+/.test(e.target.value)) {
                // ltrim() while keeping the current caret position
                let caret = e.target.selectionStart;
                const length = e.target.value.length;
                e.target.value = e.target.value.replace(/^\s+/, '');
                caret = Math.max(0, caret - (length - e.target.value.length));
                e.target.setSelectionRange(caret, caret);
            }
            this.text = e.target.value; // @NOTE: This causes performance issues when called on keyup events because it triggers VueJS recomputing!
            this.applyValue(e);
            return this;
        },

        /**
         * Apply changed value
         *
         * @param {Event|KeyboardEvent|FocusEvent} e
         */
        applyValue(e) {
            let hasChanged = false;
            const trimmed = (['input', 'keyup'].includes(e.type)) ? this.text.trimStart() : this.text.trim();
            // Update the model value
            if (this.model !== null && this.property !== null) {
                if (this.model[this.property] !== trimmed) {
                    this.model[this.property] = trimmed;
                    hasChanged = true;
                }
            } else if (this.initialValue !== trimmed) {
                hasChanged = true;
            }
            // Trigger change event (only if the value is different from the initial value)
            if (hasChanged) {
                this.$emit('change', trimmed, e, this);
            }
            return this;
        },

        /**
         * Reset to initial value
         */
        resetValue() {
            // Reset to initial value
            if (this.model !== null && this.property !== null && typeof this.model[this.property] === 'string') {
                this.text = this.model[this.property].trim();
            } else {
                this.text = (typeof this.initialValue === 'string') ? this.initialValue.trim() : '';
            }
            if (this.text === '' && this.required && this.previousValue !== null) {
                this.text = this.previousValue;
            }
            // Reset caret and scroll position
            this.resetCaretPosition();
            return this;
        },

        /**
         * Reset caret and scroll position
         */
        resetCaretPosition() {
            // @NOTE: setSelectionRange() is not supported on email inputs
            if (this.type === 'email') {
                return this;
            }

            if (this.$refs.domElement) {
                // Reset caret position:
                this.$refs.domElement.setSelectionRange(0, 0);
            }
            return this;
        }
    },
    watch: {

        initialValue() {
            // Update internal value when the initial value changes (but only if not using model+property)
            if (this.model === null && this.text !== this.initialValue) {
                this.text = this.initialValue.trim();
                this.previousValue = this.text;
            }
        }
    }
});
</script>

<style scoped>

.passwordinput {
    position: relative;
    display: block;

    &:not(:last-child, :only-child) {
        margin-bottom: var(--forminput-spacing);
    }

    label {
        display: block;
        padding-bottom: var(--typo-spacing-default);
        margin-bottom: 0;
        cursor: pointer;
    }

    &.disabled label {
        cursor: default;
    }

    &.disabled input {
        background-color: transparent;
    }

    &.disabled .input-wrapper {
        background-color: var(--background-color-white);
    }

    &.has-label {
        display: flex;
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        &.no-wrap {
            flex-wrap: nowrap;
        }


        & > label {
            flex-grow: 1;
            flex-basis: 60px;
            margin: 0;
            padding: 0;
            font-family: inherit;
        }
    }

    .input-wrapper {
        display: flex;
        border-radius: var(--forminput-border-radius);
        background-color: var(--background-color-white);
        border: var(--forminput-border);
        transition: border-color .1s;
        align-items: center;
        overflow: hidden;
        padding-right: 6px;
    }

    input {
        display: block;
        width: 100%;
        height: 27px;
        padding: 0 8px 0 8px;
        border: 0 none;
        overflow: hidden;
        text-overflow: ellipsis;

        &:focus {
            overflow: auto;
        }
    }

    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }

    .error-msg {
        display: block;
        overflow: hidden;
        max-height: 0;
        padding-top: 5px;
        margin: -5px 0 0 0;
        transition: max-height .2s, margin .2s, padding .2s;
        color: var(--color-signal);
        font-size: var(--font-size-small);
    }

    input {
        border: var(--forminput-border);
        transition: border-color .1s;

        border: none;

        &:hover,
        &:focus {
            border-color: var(--color-primary-hover);
        }

        &[disabled],
        &[disabled]:hover {
            border: var(--forminput-border);
            border: none;
        }
    }

    &.error {
        input,
        textarea {
            border-bottom-color: var(--color-signal);

            &:hover,
            &:focus {
                border-color: var(--color-primary-hover);
            }

            &[disabled],
            &[disabled]:hover {
                border-color: var(--color-signal);
            }
        }

        .error-msg {
            margin-top: 0;
            padding-top: var(--typo-spacing-default);
            max-height: 80px;
        }
    }
}

</style>
