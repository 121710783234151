<template>

    <main id="layout-main">
        <PageHeader :page-title="trans('labels.privacy_policy.title')" />

        <div id="layout-content">
            <div id="content">

                <TextInput
                    ref="policyInput"
                    type="textarea"
                    class="privacy-policy"
                    name="html"
                    :initial-value="privacyPolicyText"
                    :maxlength="60000"
                    :min-length-for-adding-tooltip="66000"
                    :placeholder="trans('labels.privacy_policy.form_description')"
                />

                <div class="buttons">
                    <ButtonSecondary :href="route('manage')" caption="labels.cancel" />
                    <ButtonPrimary caption="labels.save" @trigger="onSubmitForm" />
                </div>

            </div>

            <!-- Dialogs -->
            <ModalProgress />
            <ModalNotification />
        </div>

    </main>

</template>

<script lang="ts">
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import EventType from '@/Utility/EventType';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import {defineComponent, inject} from 'vue';
import {manageServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import {route, trans} from '@/Utility/Helpers';
import AuthorizationError from '@/Errors/AuthorizationError';

export default defineComponent({

    components: {
        ButtonPrimary,
        ButtonSecondary,
        ModalNotification,
        ModalProgress,
        PageHeader,
        TextInput,
    },

    props: {
        privacyPolicyText: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            manageService: inject(manageServiceKey),
        }
    },

    methods: {
        route,
        trans,

        onSubmitForm() {
            const textarea = (this.$refs.policyInput as typeof TextInput).$refs.domElement as HTMLTextAreaElement;

            let value = textarea.value.trim();

            if (value === '') {
                value = 'null';
            }

            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));

            this.manageService!.updatePrivacyPolicy(value)
                .then((purifiedValue: string|null) => {
                    textarea.value = purifiedValue || '';
                    this.$toast.success(trans('labels.privacy_policy.update_success'))
                    textarea.focus();
                })
                .catch(this.onErrorApi)
                .finally(() => this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE));
        },

        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root!.forceLogout;
            }
            this.$root!.showErrorDialog(error);
        },
    }
});
</script>

<style lang="css" scoped>
    .privacy-policy :deep(textarea) {
        min-height: 60vh;
        max-height: 70vh;
    }
</style>
