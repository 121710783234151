<template>
    <main id="layout-main" :data-deleting="isDeleting" :data-loading="isLoading">

        <PageHeader
            :page-title="trans('tenants.index.headline')"
        />

        <div id="layout-content">
            <div id="content" v-not-focusable>
                <div class="container list-filters-wrapper">
                    <ButtonPrimary
                        v-if="canCreateTenants"
                        :href="route('tenants.createForm')"
                        caption="labels.create_tenant"
                        class="btn-add-tenant"
                        icon="icon_add"
                    />

                    <ListFiltersBar
                        ref="listFiltersBar"
                        :filter-words="searchKeywords"
                        :show-search-button="true"
                        :sort-descending="sortDescending"
                        :sorting-filters="sortingFilters"
                        @change="applyFilters"
                        @reset="onResetFilters"
                        @trigger-search="onTriggerSearch"
                    />
                </div>

                <div class="container">
                    <div class="instance-tenants-list">
                        <div class="head row">
                            <div />
                            <div />
                            <div>{{ trans('labels.name') }}</div>
                            <div>{{ trans('labels.uid') }}</div>
                            <div>{{ trans('labels.created_at') }}</div>
                            <div>{{ trans('labels.updated_at') }}</div>
                            <div />
                            <div />
                        </div>
                        <TenantListItem
                            v-for="tenant in tenants"
                            :key="tenant.uid"
                            :tenant="tenant"
                            @delete="deleteTenant"
                            @switch="switchTenant"
                        />
                    </div>

                    <template v-if="!isLoading && tenants.length === 0">
                        <NoItemsFound v-if="searchKeywords" />
                        <NoItemsAvailable v-else />
                    </template>
                </div>

                <!-- Pagination -->
                <Pagination
                    v-if="tenants.length > 0"
                    :current-page="pagingMetadata.current_page"
                    :number-of-pages="pagingMetadata.last_page"
                    @click="onPageClicked"
                />
            </div>

            <ModalDeleteTenant />
            <ModalNotification />
            <ModalProgress />

        </div>
    </main>
</template>

<script lang="ts">

import TenantListItem from '@/Vue/Tenants/TenantListItem';
import EventType from '@/Utility/EventType';
import AuthorizationError from '@/Errors/AuthorizationError';
import {permission, route, trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import {defineComponent, inject} from 'vue';
import {tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import type Tenant from '@/Models/Tenant/Tenant';
import Pagination from '@/Vue/Common/Pagination.vue';
import type PagingMetadata from '@/Models/PagingMetadata';
import ListFiltersBar from '@/Vue/Common/ListFiltersBar.vue';
import NoItemsAvailable from '@/Vue/Search/NoItemsAvailable.vue';
import NoItemsFound from '@/Vue/Search/NoItemsFound.vue';
import SortingFilters from '@/Filters/SortingFilters';
import type FilterCategory from '@/Filters/FilterCategory';
import ModalDeleteTenant from '@/Vue/Modals/ModalDeleteTenant.vue';

export default defineComponent({
    name: 'PageTenantList',

    components: {
        ModalDeleteTenant,
        NoItemsFound,
        NoItemsAvailable,
        ListFiltersBar,
        Pagination,
        PageHeader,
        ButtonPrimary,
        ModalProgress,
        ModalNotification,
        TenantListItem,
    },

    data() {
        return {
            tenantService: inject(tenantServiceKey)!,

            sortingFilters: [
                SortingFilters.Name.setActive(true, true),
                SortingFilters.CreatedAt,
                SortingFilters.UpdatedAt,
            ],

            sortDescending: false,

            searchKeywords: null as string | null,
        };
    },

    computed: {

        canCreateTenants() {
            return permission(Permission.TenantsCreate());
        },

        /**
         * @return the current metadata about the page from the service
         */
        pagingMetadata(): PagingMetadata {
            return this.tenantService.instanceTenantsPage.metadata;
        },

        /**
         * Get the list of tenants from the service
         */
        tenants(): Tenant[] {
            return this.tenantService.instanceTenantsPage.items;
        },

        isLoading() {
            if (this.tenantService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        isDeleting() {
            if (this.tenantService.isDeleting) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.deleting_tenant'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        currentTenantUid(): string | undefined {
            return window.currentUser?.tenant?.uid;
        },
    },

    mounted() {
        this.$globalEvents.on(EventType.MODAL_DELETE_TENANT_APPLY, this.onApplyConfirmDelete);

        this.fetchTenants();
    },

    beforeUnmount() {
        this.$globalEvents.off(EventType.MODAL_DELETE_TENANT_APPLY, this.onApplyConfirmDelete);
    },

    methods: {
        trans,
        route,

        /**
         * @param tenant tenant to work in from now on
         */
        switchTenant(tenant: Tenant) {
            this.tenantService
                .switchTenantAndReload(tenant)
                .catch(this.onErrorApi);
            return this;
        },

        onPageClicked(page: number) {
            this.fetchTenants(page);
        },

        deleteTenant(tenant: Tenant) {
            this.$globalEvents.emit(EventType.MODAL_DELETE_TENANT_SHOW, tenant);
        },

        onApplyConfirmDelete(tenant: Tenant) {
            this.tenantService
                .deleteTenant(tenant)
                .catch(this.onErrorApi)
                .then(() => {
                    if (tenant.uid === this.currentTenantUid) {
                        // reload page to update current tenant
                        this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                        window.location.reload();
                    } else {
                        // reload data only - current tenant is still available
                        return this.fetchTenants();
                    }
                });
        },

        /**
         * Fetch all tenants for this instance from API
         */
        fetchTenants(page: number = 1) {
            const orderBy = this.sortingFilters.find(f => f.isActive)?.paramName || null;
            this.tenantService
                .fetchInstanceTenants(page, this.searchKeywords, orderBy, this.sortDescending)
                .catch(this.onErrorApi);
            return this;
        },

        onResetFilters() {
            this.searchKeywords = null;
            this.fetchTenants();
        },

        applyFilters(filterObj: FilterCategory | string | object | null | undefined = undefined) {
            // Store filter keywords text (no search trigger needed):
            if (filterObj === null || typeof filterObj === 'string') {
                this.searchKeywords = filterObj;
            }

            // Order by ascending/descending:
            if (
                filterObj &&
                typeof filterObj === 'object' && Object.prototype.hasOwnProperty.call(filterObj, 'descending')
            ) {
                this.sortDescending = (filterObj as any).descending ?? this.sortDescending;
            }

            // Trigger the search:
            if (filterObj !== null && typeof filterObj === 'object') {
                this.fetchTenants();
            }
        },

        onTriggerSearch(_: KeyboardEvent, keywords: string) {
            this.searchKeywords = keywords;
            this.fetchTenants();
        },

        /**
         * Error handler for API errors
         */
        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root!.forceLogout;
            }

            this.$root!.showErrorDialog(error);
            return this;
        },
    }
});
</script>

<style lang="css" scoped>

#content > .container {
    max-width: var(--container-max-width);
    padding-left: 0;
}

.list-filters {
    width: auto;
}

.instance-tenants-list {
    display: grid;
    row-gap: 4px;
    grid-template-columns: 100px min-content auto auto repeat(2, max-content) repeat(2, min-content);
    margin-bottom: 16px;

    .row {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: span 8;
        margin-left: 0;
        margin-right: 0;
        border-radius: 8px;
        padding: 10px;

        &.head {
            color: white;
            background-color: var(--color-anthracite);
        }
    }
}

</style>
