import type AbstractDataObject from '@/Models/AbstractDataObject';
import {uuid4} from '@/Utility/Helpers';

/**
 * @internal
 */
export class ClipboardData {

    public static readonly instanceUid = uuid4();

    public readonly data: any;
    public readonly clipboardUid: string;
    public readonly appVersion: string;

    constructor(data: any, clipboardUID: string | null = null, appVersion: string | null = null) {
        this.data = data;
        this.clipboardUid = clipboardUID ?? ClipboardData.instanceUid;
        this.appVersion = appVersion ?? window.appData.APP_VERSION;
    }

    get isArray(): boolean {
        return (this.data instanceof Array);
    }

    get isBoolean(): boolean {
        return (typeof this.data === 'boolean');
    }

    get isEmpty(): boolean {
        return (this.data === null || (this.isArray && this.data.length === 0) || (this.isObject && Object.keys(this.data).length === 0));
    }

    get isModel(): boolean {
        return (this.isObject && typeof this.data.constructorName === 'string');
    }

    isInstanceOf(constructor: typeof AbstractDataObject): boolean {
        return (this.isModel && this.data.constructorName === constructor.constructorName);
    }

    get isNumber(): boolean {
        return (typeof this.data === 'number');
    }

    get isObject(): boolean {
        return (this.data instanceof Object && !(this.data instanceof Array));
    }

    get isString(): boolean {
        return (typeof this.data === 'string');
    }

    serialize(): string {
        return JSON.stringify({
            data: this.data,
            clipboardUID: this.clipboardUid,
            appVersion: this.appVersion,
        });
    }

    static deserialize(input: string): ClipboardData {
        const data = JSON.parse(input);
        return new ClipboardData(data.data, data.clipboardUID || null, data.appVersion || 'unknown');
    }
}
