import {sortArrayByDate, sortArrayByProperty, trans} from '@/Utility/Helpers';
import FilterCategory from "@/Filters/FilterCategory";

/**
 * Filter definitions
 */
const FilterDefinitions = {

    Alphabetical: {
        title: trans('labels.alphabetical'),
        callback(elements, options = null) {
            return sortArrayByProperty(
                elements,
                options?.property || this.paramName,
                (typeof options?.descending === 'boolean') ? options.descending : this.descending
            );
        },
        descending: false,
        paramName: 'title',
    },

    Name: {
        title: trans('labels.name'),
        callback(elements, options = null) {
            return sortArrayByProperty(
                elements,
                options?.property || this.paramName,
                (typeof options?.descending === 'boolean') ? options.descending : this.descending
            );
        },
        descending: false,
        paramName: 'name',
    },

    Firstname: {
        title: trans('labels.firstname'),
        callback(elements, options = null) {
            return sortArrayByProperty(
                elements,
                options?.property || this.paramName,
                (typeof options?.descending === 'boolean') ? options.descending : this.descending
            );
        },
        descending: false,
        paramName: 'firstname',
    },

    Lastname: {
        title: trans('labels.lastname'),
        callback(elements, options = null) {
            return sortArrayByProperty(
                elements,
                options?.property || this.paramName,
                (typeof options?.descending === 'boolean') ? options.descending : this.descending
            );
        },
        descending: false,
        paramName: 'lastname',
    },

    Email: {
        title: trans('labels.email'),
        callback(elements, options = null) {
            return sortArrayByProperty(
                elements,
                options?.property || this.paramName,
                (typeof options?.descending === 'boolean') ? options.descending : this.descending
            );
        },
        descending: false,
        paramName: 'email',
    },

    CreatedAt: {
        title: trans('labels.created_at'),
        callback(elements, options = null) {
            return sortArrayByDate(
                elements,
                options?.property || this.paramName,
                (typeof options?.descending === 'boolean') ? options.descending : this.descending
            );
        },
        descending: true,
        paramName: 'created_at',
    },

    UpdatedAt: {
        title: trans('labels.last_updated'),
        callback(elements, options = null) {
            return sortArrayByDate(
                elements,
                options?.property || this.paramName,
                (typeof options?.descending === 'boolean') ? options.descending : this.descending
            );
        },
        descending: true,
        paramName: 'updated_at',
    },
};

export default class SortingFilters
{
    static get Alphabetical() { return new FilterCategory(FilterDefinitions.Alphabetical); }
    static get Name() { return new FilterCategory(FilterDefinitions.Name); }
    static get Firstname() { return new FilterCategory(FilterDefinitions.Firstname); }
    static get Lastname() { return new FilterCategory(FilterDefinitions.Lastname); }
    static get Email() { return new FilterCategory(FilterDefinitions.Email); }
    static get CreatedAt() { return new FilterCategory(FilterDefinitions.CreatedAt); }
    static get UpdatedAt() { return new FilterCategory(FilterDefinitions.UpdatedAt); }

    /**
     * Constructor
     */
    constructor()
    {
        if (this.constructor === SortingFilters)
        {
            throw new TypeError('Static class "SortingFilters" cannot be instantiated directly.');
        }
    }
}
