import {parseDate} from '@/Utility/Helpers';

type StatusLabelConfigAttributes = {
    /**
     * Optional type (e.g. 'policy', 'status', 'notification')
     */
    type?: 'policy' | 'status' | 'disabled' | 'notification' | 'warning' | null;

    /**
     * Optional custom caption text identifier (e.g. 'statuslabels.unreleased_changes')
     */
    caption?: string | null;

    /**
     * Optional date suffix
     */
    date?: Date | string | null;

    /**
     * Optional icon name (e.g. 'icon_history')
     */
    icon?: string | null;

    /**
     * Optional tooltip text (e.g. 'statuslabels.unreleased_changes_tooltip')
     */
    tooltip?: string | null;

    /**
     * Optional function that will be called on click. If omitted or null, the label is not clickable.
     */
    callback?: (() => void) | null;
}

export default class StatusLabelConfig {
    public readonly type: 'policy' | 'status' | 'disabled' | 'notification' | 'warning' | null;
    public readonly caption: string | null;
    public readonly date: Date | null;
    public readonly icon: string | null;
    public readonly tooltip: string | null;
    public readonly callback: (() => void) | null;

    constructor(attributes: StatusLabelConfigAttributes = {}) {
        this.type = attributes.type || null;
        this.caption = attributes.caption || null;
        this.date = parseDate(attributes.date) || null;
        this.icon = attributes.icon || null;
        this.tooltip = attributes.tooltip || null;
        this.callback = attributes.callback || null;
    }
}
