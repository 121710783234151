<template>
    <li
        id="sidemenu-item-current-team"
        :class="{ active: isActive, 'nav-item': true, 'extra-menu': true }"
        data-ug-id="sidemenu-item-current-team"
    >
        <a
            id="navbarTeamDropdown"
            ref="teamDropdown"
            :title="tenantNameTooltip"
            aria-expanded="false"
            aria-haspopup="true"
            class="dropdown-toggle"
            data-bs-auto-close="outside"
            data-bs-toggle="dropdown"
            role="button"
            tabindex="0"
        >
            <Icon name="icon_tenants" />
            <span class="sidemenu-item-text">
                {{ trans('labels.tenant') }}:
            </span>
            <b id="sidemenu-item-tenant-name">
                {{ tenantName }}
            </b>
        </a>

        <!-- Extended menu items -->
        <div
            aria-labelledby="navbarTeamDropdown"
            class="dropdown-menu dropdown-menu-right"
        >
            <section class="team">
                <div class="details">
                    <div class="details-tenant-name">
                        <b>{{ tenantName }}</b>
                    </div>
                    <div class="details-role is-grey">
                        {{ tenantRole }}
                    </div>
                </div>

                <!-- Switch Tenants -->
                <a
                    v-if="canSwitchTenants"
                    :title="trans('labels.change_tenant')"
                    class="dropdown-item"
                    data-ug-id="sidemenu-item-user-switch_tenants"
                    @click="onSwitchTenantClick"
                >
                    <Icon name="icon_tenants" />
                    <span class="label">{{ trans('labels.change_tenant') }}</span>
                </a>

            </section>

            <section v-if="canUpdateTenant || canManageMembers" class="team-settings">

                <!-- Settings -->
                <a
                    v-if="canUpdateTenant"
                    :href="route('tenants.updateForm', {tenant: user!.tenant!.uid})"
                    :title="trans('labels.tenant_settings')"
                    class="dropdown-item"
                    data-ug-id="sidemenu-item-user-tenant_settings"
                >
                    <Icon name="icon_settings" />
                    <span class="label">{{ trans('labels.tenant_settings') }}</span>
                </a>

                <!-- Members -->
                <a
                    v-if="canManageMembers"
                    :href="route('users.index')"
                    :title="trans('labels.tenant_members')"
                    class="dropdown-item"
                    data-ug-id="sidemenu-item-user-tenant_members"
                >
                    <Icon name="icon_users" />
                    <span class="label">{{ trans('labels.tenant_members') }}</span>
                </a>

            </section>
        </div>
    </li>
</template>

<script lang="ts">
import {route, trans} from '@/Utility/Helpers';
import Icon from '@/Vue/Common/Icon.vue';
import type User from '@/Models/User/User';
import {Permission} from '@/Models/User/Permission';
import EventType from '@/Utility/EventType';
import type Tenant from '@/Models/Tenant/Tenant';
import AuthorizationError from '@/Errors/AuthorizationError';
import {Dropdown} from '~bootstrap';
import {inject} from 'vue';
import {tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';

export default {
    name: 'SidemenuItemUserTenant',

    components: {
        Icon
    },

    data() {
        return {
            tenantService: inject(tenantServiceKey)!,
        };
    },

    computed: {

        canSwitchTenants() {
            return (
                this.$gate.allows(Permission.TenantsReadAny())
                || this.user.belongsToMultipleTenants()
            );
        },

        canManageMembers() {
            return this.$gate.allows(Permission.ability(Permission.TenantsReadUsers()), this.user.tenant);
        },

        canUpdateTenant() {
            return this.$gate.allows(Permission.ability(Permission.TenantsUpdate()), this.user.tenant);
        },

        isActive(): boolean {
            return window.location.pathname.startsWith('/tenants') || window.location.pathname.startsWith('/users');
        },

        user(): User {
            return window.currentUser!;
        },

        tenantName(): string {
            return this.user.tenant?.name || trans('labels.unknown');
        },

        tenantNameTooltip() {
            return `${this.tenantName} (${this.tenantRole})`;
        },

        tenantRole(): string {
            if (!this.user.tenant_role) {
                throw new Error('User and tenant role must be set');
            }

            return trans('tenant_roles.' + this.user.tenant_role.name);
        },
    },

    mounted() {
        this.$globalEvents.on(EventType.MODAL_SAVE_OR_DISMISS_CHANGES_SHOW, this.onShowSaveOrDismissDialog);
        this.$globalEvents.on(EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload);
    },

    beforeUnmount() {
        this.$globalEvents.off(EventType.MODAL_SAVE_OR_DISMISS_CHANGES_SHOW, this.onShowSaveOrDismissDialog);
        this.$globalEvents.off(EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload);
    },

    methods: {
        route,
        trans,

        hideMenu() {
            Dropdown.getInstance(this.$refs.teamDropdown)?.hide();
        },

        onBeforeUnload() {
            this.hideMenu();
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
        },

        onShowSaveOrDismissDialog() {
            this.hideMenu();
            this.$globalEvents.emit(EventType.MODAL_SELECT_TENANT_HIDE);
        },

        onSwitchTenantClick() {
            this.hideMenu();
            this.$globalEvents.emit(EventType.MODAL_SELECT_TENANT_SHOW, this.onTenantSelected);
        },

        onTenantSelected(tenant: Tenant) {
            if (window.currentUser!.isCurrentTenant(tenant)) {
                return;
            }

            this.$globalEvents.off(EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload);
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.changing_tenant'));

            this.tenantService
                .switchTenantAndReload(tenant)
                .catch(this.onErrorApi);
        },

        onErrorApi(error: string | Error) {
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);

            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }

            this.$root?.showErrorDialog(error);
        },
    }
};
</script>

<style lang="css" scoped>

#navbarTeamDropdown {
    height: 64px;
}

@media screen and (max-height: 679px) {
    #navbarTeamDropdown {
        height: 54px;
    }
}

</style>
