<template>
    <main
        id="layout-main"
        :data-deleting="isDeleting"
        :data-loading="isLoading"
        :data-saving="isSaving"
    >
        <PageHeader
            :page-title="trans('users.index.headline')"
        />

        <div id="layout-content">
            <div
                id="content"
                ref="content"
                v-not-focusable
            >
                <div class="container list-filters-wrapper">
                    <ListFiltersBar
                        ref="listFiltersBar"
                        :filter-words="searchKeywords"
                        :show-search-button="true"
                        :sort-descending="sortDescending"
                        :sorting-filters="sortingFilters"
                        @change="applyFilters"
                        @reset="onResetFilters"
                        @trigger-search="onTriggerSearch"
                    />
                </div>

                <!-- List of users -->
                <div class="container">

                    <div v-if="users.length > 0" class="instance-users-list">
                        <div class="head row">
                            <div :title="trans('labels.identity_provider')">
                                <Icon name="icon_identity-provider" />
                            </div>
                            <div />
                            <div>{{ trans('labels.name') }}</div>
                            <div>{{ trans('labels.email') }}</div>
                            <div>{{ trans('labels.role') }}</div>
                            <div>{{ trans('labels.created_at') }}</div>
                            <div>{{ trans('labels.updated_at') }}</div>
                            <div>{{ trans('labels.tenants') }}</div>
                            <div />
                        </div>
                        <InstanceUserListItem
                            v-for="user in users"
                            :key="user.uid"
                            :user="user"
                            @delete="onTriggerUserDelete"
                        />
                    </div>

                    <template v-if="!isLoading && users.length === 0">
                        <NoItemsFound v-if="searchKeywords" />
                        <NoItemsAvailable v-else />
                    </template>
                </div>

                <!-- Pagination -->
                <Pagination
                    v-if="users.length > 0"
                    :current-page="pagingMetadata.current_page"
                    :number-of-pages="pagingMetadata.last_page"
                    @click="onPageClicked"
                />

            </div>

            <ModalDeleteUser />
            <ModalProgress />
            <ModalNotification />
        </div>
    </main>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {userServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import {permission, route, trans} from '@/Utility/Helpers';
import ListFiltersBar from '@/Vue/Common/ListFiltersBar.vue';
import NoItemsAvailable from '@/Vue/Search/NoItemsAvailable.vue';
import NoItemsFound from '@/Vue/Search/NoItemsFound.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import AuthorizationError from '@/Errors/AuthorizationError';
import SortingFilters from '@/Filters/SortingFilters';
import {Permission} from '@/Models/User/Permission';
import EventType from '@/Utility/EventType';
import type User from '@/Models/User/User';
import type FilterCategory from '@/Filters/FilterCategory';
import InstanceUserListItem from '@/Vue/Admin/InstanceUserListItem.vue';
import Pagination from '@/Vue/Common/Pagination.vue';
import type PagingMetadata from '@/Models/PagingMetadata';
import type PagingPage from '@/Models/PagingPage';
import ModalDeleteUser from '@/Vue/Modals/ModalDeleteUser.vue';
import Icon from '@/Vue/Common/Icon.vue';

export default defineComponent({
    name: 'InstanceUserList',

    components: {
        Icon,
        ModalDeleteUser,
        Pagination,
        InstanceUserListItem,
        ModalNotification,
        ModalProgress,
        NoItemsFound,
        NoItemsAvailable,
        ListFiltersBar,
        PageHeader
    },

    data() {
        return {
            userService: inject(userServiceKey)!,

            sortingFilters: [
                SortingFilters.Firstname,
                SortingFilters.Lastname.setActive(true, true),
                SortingFilters.Email,
                SortingFilters.CreatedAt,
                SortingFilters.UpdatedAt,
            ],

            sortDescending: false,

            searchKeywords: null as string | null,

            events: new Map([
                [EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload],
                [EventType.MODAL_DELETE_USER_APPLY, this.onConfirmUserDelete],
            ]),
        };

    },

    computed: {

        currentTenantUid() {
            return window.currentUser?.tenant?.uid;
        },

        listFiltersBar() {
            return this.$refs.listFiltersBar as InstanceType<typeof ListFiltersBar>;
        },

        contentElement() {
            return this.$refs.content as HTMLElement;
        },

        canAccessUsers() {
            return permission(Permission.UsersReadInstanceWide());
        },

        /**
         * @return the current metadata about the page from the service
         */
        pagingMetadata(): PagingMetadata {
            return this.userService.instanceUserPage.metadata;
        },

        users(): User[] {
            return this.userService.instanceUserPage.items;
        },

        isLoading(): boolean {
            if (this.userService!.isLoading || this.userService!.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        isSaving(): boolean {
            if (this.userService!.isSaving || this.userService!.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        isDeleting(): boolean {
            if (this.userService!.isDeleting || this.userService!.isDeleting) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.deleting_user'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },
    },

    mounted() {
        // Fetch users:
        if (this.canAccessUsers) {
            this.fetchUsersPage();
        }

        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });
    },

    beforeUnmount() {
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    methods: {

        trans,
        route,

        fetchUsersPage(page: number = 1) {
            const orderBy = this.sortingFilters.find(f => f.isActive)?.paramName || null;
            this.userService
                .fetchInstanceUsers(page, null, this.searchKeywords, orderBy, this.sortDescending)
                .then(this.onSuccessFetchUsers)
                .catch(this.onErrorApi);
        },

        deleteUser(user: User) {
            this.userService
                .deleteInstanceUser(user.uid)
                .catch(this.onErrorApi);
        },

        onSuccessFetchUsers(_: PagingPage<User>) {
            // Scroll to top:
            this.contentElement.scrollTo(0, 0);
        },

        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root!.forceLogout;
            }
            this.$root!.showErrorDialog(error);
        },

        onResetFilters() {
            this.searchKeywords = null;
            this.sortDescending = true;
            this.listFiltersBar.setFocus();
            this.fetchUsersPage(1);
        },

        applyFilters(filterObj: FilterCategory | string | object | null | undefined = undefined) {
            // Store filter keywords text (no search trigger needed):
            if (filterObj === null || typeof filterObj === 'string') {
                this.searchKeywords = filterObj;
            }

            // Order by ascending/descending:
            if (
                filterObj &&
                typeof filterObj === 'object' && Object.prototype.hasOwnProperty.call(filterObj, 'descending')
            ) {
                this.sortDescending = (filterObj as any).descending ?? this.sortDescending;
            }

            // Trigger the search:
            if (filterObj !== null && typeof filterObj === 'object') {
                this.fetchUsersPage(1);
            }
        },

        onTriggerSearch(_: KeyboardEvent, keywords: string) {
            this.searchKeywords = keywords;
            this.fetchUsersPage(1);
        },

        onBeforeUnload(_: BeforeUnloadEvent) {
            this.userService!.cancelRequests();
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
        },

        onPageClicked(page: number) {
            this.fetchUsersPage(page);
        },

        onTriggerUserDelete(user: User) {
            this.$globalEvents.emit(EventType.MODAL_DELETE_USER_SHOW, user);
        },

        onConfirmUserDelete(user: User) {
            this.deleteUser(user);
        },
    }
});
</script>

<style lang="scss" scoped>

.instance-users-list {
    display: grid;
    row-gap: 4px;
    grid-template-columns: min-content min-content repeat(6, auto) min-content;
    margin-bottom: 16px;

    .row {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: span 9;
        margin-left: 0;
        margin-right: 0;
        border-radius: 8px;
        padding: 10px;

        &.head {
            color: white;
            background-color: var(--color-anthracite);

            div:has(.icon) {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .icon {
                width: 20px;
                height: 20px;
            }
        }
    }
}

</style>
