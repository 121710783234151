import AuthorizationError from '@/Errors/AuthorizationError';
import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import AxiosRequest from '@/Services/AxiosRequest';
import {getUidReferencesFromObject, route, trans} from '@/Utility/Helpers';
import Asset from '@/Models/Asset/Asset';
import AssetFactory from '@/Models/Asset/AssetFactory';

export default class AssetService
{
    /**
     * Constructor
     */
    constructor()
    {
        this.assets = [];                   // List of cached assets
        this.isLoading = false;             // Loading state
        this.isSaving = false;              // Saving state
        this.request = null;                // The current request
    }

    /**
     * Cancel any ongoing requests
     *
     * @async
     * @returns {Promise}
     */
    async cancelRequests() {
        // @NOTE: Only working with a single request at the moment!
        if (this.request !== null)
        {
            return await this.request.cancel();
        }
        return Promise.resolve('Requests canceled');
    }

    /**
     * Creates a new asset through the API.
     *
     * @async
     * @param {FormData} formData
     * @param {(progressEvent: any) => void} onProgressHandler
     * @return {Promise<Asset>}
     */
    async createAssetFromFormData(formData, onProgressHandler = null) {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.post(
            route('api.assets.create'),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: onProgressHandler
            }
        ).then(({data}) => {
            try {
                const asset = AssetFactory.createFromAttributes(data.data);
                // Add the new asset to the originally fetched list
                this.setAsset(asset);
                //console.info('AssetService->createAssetFromFormData(): Asset created.', asset, data);
                return Promise.resolve(asset);
            } catch (ex) {
                console.error('AssetService->createAssetFromFormData(): API returned invalid or incompatible asset data.', data, ex);
                return Promise.reject(trans('errors.asset.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Fetch all assets for the current user from API
     *
     * @async
     * @returns {Promise}
     */
    async fetchAssets() {
        if (this.isLoading === true || this.request !== null && this.request.isBusy === true)
        {
            throw new ServiceIsBusyError();
        }
        this.isLoading = true;
        this.request = new AxiosRequest();
        return await this.request.get(
                route('api.assets.index')
            ).then(({ data }) => {
                this.assets = [];
                const assetsData = data.data;
                Object.keys(assetsData).map((key) => {
                    try {
                        this.assets.push(AssetFactory.createFromAttributes(assetsData[key]));
                    }catch(ex) {
                        console.warn('AssetService->fetchAssets(): Skipping asset with invalid or incompatible data.', assetsData[key], ex);
                    }
                });
                return Promise.resolve(this.assets);
            }).finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Get a specific asset by its UID
     *
     * @param {String} uid
     * @returns {Asset|null}
     */
    getAssetByUid(uid) {
        if (uid === undefined || uid === null) { return null; }
        return this.assets.find((asset) => asset.uid === uid) || null;
    }

    /**
     * Get all known assets referenced inside an object
     *
     * @param {Object|Array} objWithAssetUids
     * @returns {Asset[]}
     */
    getReferencedAssetsFromObject(objWithAssetUids) {
        if (objWithAssetUids === undefined || objWithAssetUids === null) { return []; }
        const allUids = getUidReferencesFromObject(objWithAssetUids);
        return this.assets.filter(a => allUids.includes(a.uid));
    }

    /**
     * Delete unused assets through the API
     *
     * @async
     * @returns {Promise}
     */
    async deleteUnusedAssets() {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true)
        {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.delete(
            route('api.assets.delete.unused')
        ).then(({ data }) => {
            try {
                const assets = data.data;
                // Remove the assets from the originally fetched list:
                this.removeAssets(assets);
                return Promise.resolve(assets);
            }catch(ex) {
                console.error('AssetService->deleteUnusedAssets(): API returned invalid or incompatible asset data.', data, ex);
                return Promise.reject(trans('errors.asset.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Delete an asset through the API
     *
     * @async
     * @param {Asset} asset
     * @returns {Promise<void>}
     */
    async deleteAsset(asset) {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true)
        {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.delete(
            route('api.assets.delete', {asset: asset.uid})
        ).then(({ data }) => {
            try {
                // Remove the asset from the originally fetched list:
                this.removeAsset(asset);
                return Promise.resolve(asset);
            } catch(ex) {
                console.error('AssetService->deleteAsset(): API returned invalid or incompatible asset data.', data, ex);
                return Promise.reject(trans('errors.asset.invalid_data'));
            }
        }).catch((error) => {
            // Set custom error message if deleting is not allowed
            if (error instanceof AuthorizationError) {
                error.message = trans('errors.asset.delete_unauthorized');
            }
            throw error;
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Remove a specific asset from the list
     *
     * @param {Asset} asset
     */
    removeAsset(asset) {
        const assetIndex = this.assets.findIndex(a => a.uid === asset.uid);

        // Remove the asset from the cached list:
        if (assetIndex >= 0)
        {
            this.assets.splice(assetIndex, 1);
        }
        return this;
    }

    /**
     * Remove multiple assets by their IDs
     *
     * @param {string[]} asset_uids
     */
    removeAssets(asset_uids) {
        const assetsSet = new Set(asset_uids);
        this.assets = this.assets.filter(asset => assetsSet.has(asset.uid) === false);
        return this;
    }

    /**
     * Save an asset through the API
     *
     * @async
     * @param {Asset} asset
     * @returns {Promise}
     */
    async saveAsset(asset) {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true)
        {
            throw new ServiceIsBusyError();
        }
        this.isSaving = true;

        // Prepare form data if a preview file should be included:
        const dataToUpdate = {
            'title': asset.title,
            'description': asset.description,
            'attribution': asset.attribution,
        };

        let formData = dataToUpdate;
        let config = {};
        let method = 'patch';

        if (asset.previewImageForUpload instanceof File)
        {
            // Send a POST request since PATCH doesn't support files:
            formData = new FormData();
            method = 'post';
            formData.append('_method', 'PATCH');
            formData.append('data', JSON.stringify(dataToUpdate));
            formData.append('preview_image', asset.previewImageForUpload);
            config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
        }

        this.request = new AxiosRequest();
        return await this.request[method](
            route('api.assets.update', {asset: asset.uid}),
            formData,
            config
        ).then(({ data }) => {
            try {
                const asset = AssetFactory.createFromAttributes(data.data);
                // Update the modified asset in the originally fetched list:
                this.setAsset(asset);
                //console.info('AssetService->saveAsset(): Asset saved.', asset, data);
                return Promise.resolve(asset);
            }catch(ex) {
                console.error('AssetService->saveAsset(): API returned invalid or incompatible asset data.', data, ex);
                return Promise.reject(trans('errors.asset.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Set a specific asset in the list
     *
     * @param {Asset} asset
     */
    setAsset(asset) {
        const index = this.assets.findIndex(a => a.uid === asset.uid);
        // Replace or add the asset to the cached list:
        if (index >= 0)
        {
            this.assets.splice(index, 1, asset);
        }
        else
        {
            this.assets.push(asset);
        }
        return this;
    }

    /**
     * Archive an asset through the API
     *
     * @async
     * @param {Asset} asset
     * @returns {Promise}
     */
     async archiveAsset(asset) {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true)
        {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.post(
            route('api.assets.archive', {asset: asset.uid})
        ).then(({ data }) => {
            try {
                const asset = AssetFactory.createFromAttributes(data.data);
                // Update the modified asset in the originally fetched list:
                this.setAsset(asset);
                return Promise.resolve(asset);
            } catch(ex) {
                console.error('AssetService->archiveAsset(): API returned invalid or incompatible asset data.', data, ex);
                return Promise.reject(trans('errors.asset.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Restore an Archived asset through the API
     *
     * @async
     * @param {Asset} asset
     * @returns {Promise}
     */
     async restoreArchivedAsset(asset) {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true)
        {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.post(
            route('api.assets.restore_archived', {asset: asset.uid})
        ).then(({ data }) => {
            try {
                const asset = AssetFactory.createFromAttributes(data.data);
                // Update the modified asset in the originally fetched list:
                this.setAsset(asset);
                return Promise.resolve(asset);
            } catch(ex) {
                console.error('AssetService->restoreArchivedAsset(): API returned invalid or incompatible asset data.', data, ex);
                return Promise.reject(trans('errors.asset.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }


    /**
     * Add an asset to the library through the API
     *
     * @async
     * @param {Asset} asset
     * @returns {Promise}
     */
    async addAssetToLibrary(asset) {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true)
        {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.post(
            route('api.assets.add_to_library', {assetWithFilesForPlatform: asset.uid})
        ).then(({ data }) => {
            try {
                const asset = AssetFactory.createFromAttributes(data.data);
                // Update the modified asset in the originally fetched list:
                this.setAsset(asset);
                return Promise.resolve(asset);
            } catch(ex) {
                console.error('AssetService->addAssetToLibrary(): API returned invalid or incompatible asset data.', data, ex);
                return Promise.reject(trans('errors.asset.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Remove an asset from the library through the API
     *
     * @async
     * @param {Asset} asset
     * @returns {Promise}
     */
    async removeAssetFromLibrary(asset) {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true)
        {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.post(
            route('api.assets.remove_from_library', {assetWithFilesForPlatform: asset.uid})
        ).then(({ data }) => {
            try {
                const asset = AssetFactory.createFromAttributes(data.data);
                // Update the modified asset in the originally fetched list:
                this.setAsset(asset);
                return Promise.resolve(asset);
            } catch(ex) {
                console.error('AssetService->removeAssetFromLibrary(): API returned invalid or incompatible asset data.', data, ex);
                return Promise.reject(trans('errors.asset.invalid_data'));
            }
        }).catch((error) => {
            // Set custom error message if removing is not allowed
            if (error instanceof AuthorizationError) {
                error.message = trans('errors.asset.remove_unauthorized');
            }
            throw error;
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }
}
