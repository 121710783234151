<template>
    <div id="inspector" class="inspector-content" v-not-focusable>
        <InspectorHeader
            :title="trans('labels.inspector.unit_properties')"
            icon-name="icon_units"
        />

        <!-- Panels for viewing object properties -->
        <div class="panels" v-not-focusable :key="'InspectorPanels' + renderTimestamp">
            <div class="inspector-panel panel-unit" v-if="unit" :key="'PanelUnit'+unit.type">

                <!-- Preview image -->
                <InspectorItemPreviewImage
                    :src="revisionToDisplay.preview"
                    :editable="false"
                />

                <!-- Title -->
                <div class="property property-title">
                    <TextInput
                        :disabled="true"
                        :model="revisionToDisplay"
                        property="title"
                    />
                </div>

                <!-- Status labels -->
                <div class="property property-status-labels">
                    <StatusLabel :config="getTypeLabel"/>
                </div>

                <!-- Description -->
                <div v-if="revisionToDisplay.description" class="property property-description">
                    <TextInput
                        :disabled="true"
                        type="textarea"
                        :model="revisionToDisplay"
                        property="description"
                    />
                </div>

                <div v-if="canDownloadTinCanPackage" class="property">
                    <header>xAPI / TinCan Package</header>
                    <ButtonSecondary
                        caption="labels.download"
                        :href="$root.route('units.lms.xapi.download', {'unit': unit.uid})"
                        target="_blank"
                    />
                </div>

                <div v-if="canDownloadAiccPackage" class="property">
                    <header>AICC Package</header>
                    <ButtonSecondary
                        caption="labels.download"
                        :href="$root.route('units.lms.aicc.download', {'course': course.uid, 'unit': unit.uid})"
                        target="_blank"
                    />
                </div>

                <PanelUnitLinks :unit="unit" />

                <!-- Author assignments -->
                <PanelAuthorAssignment
                    v-if="showAuthorAssignment"
                    :unit="unit"
                />

                <div v-if="canLaunchUnit || canLaunchCourseUnit" class="property property-launch">
                    <header>Webapp</header>
                    <div class="buttons">
                        <ButtonPrimary
                            v-if="canLaunchCourseUnit"
                            caption="labels.learn_in_webapp"
                            :href="this.$root.route('courses.units.launch', {'course': course.uid,'unit': unit.uid})"
                            target="_self"
                            icon="icon_play"
                        />
                        <ButtonPrimary
                            v-else-if="canLaunchUnit"
                            caption="labels.learn_in_webapp"
                            :href="this.$root.route('units.launch', {'unit': unit.uid})"
                            target="_self"
                            icon="icon_play"
                        />
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    // Import VueJS components:
    import InspectorItemPreviewImage from '@/Vue/Inspector/Items/InspectorItemPreviewImage.vue';
    import PanelAuthorAssignment     from '@/Vue/Inspector/PanelAuthorAssignment.vue';

    // Import classes:
    import EventType                from '@/Utility/EventType';
    import StatusLabelConfig        from '@/Utility/StatusLabelConfig';
    import Unit                     from '@/Models/Unit/Unit';
    import UnitType                 from '@/Models/UnitData/UnitType';
    import {Permission}             from "@/Models/User/Permission";
    import ButtonSecondary          from "@/Vue/Common/ButtonSecondary.vue";
    import ButtonPrimary            from "@/Vue/Common/ButtonPrimary.vue";
    import Course                   from "@/Models/Course/Course";
    import {inject}                 from "vue";
    import {featureRepositoryKey, unitLinkServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
    import {Feature}                from "@/Models/Features/Feature";
    import PanelUnitLinks from "@/Vue/Sidepanel/UnitLinks/PanelUnitLinks.vue";
    import TextInput from "@/Vue/Common/TextInput.vue";
    import StatusLabel from "@/Vue/Common/StatusLabel.vue";
    import InspectorHeader from '@/Vue/Inspector/InspectorHeader.vue';
    import {trans} from '@/Utility/Helpers';

    export default {
        name: 'SidepanelViewUnit',
        components: {
            InspectorHeader,
            StatusLabel,
            TextInput,
            ButtonPrimary,
            ButtonSecondary,
            InspectorItemPreviewImage,
            PanelAuthorAssignment,
            PanelUnitLinks,
        },
        props: {
            unit: {
                type: Unit,
                default: null,
            },
            showAuthorAssignment: {
                type: Boolean,
                default: false,
            },
            course: {
                type: Course,
                default: null,
            },
        },
        data() {
            return {
                featureRepository: inject(featureRepositoryKey),
                unitTypes: UnitType.all,                        // List of unit types for creating the selection buttons
                renderTimestamp: null,                          // Helper for forcing re-rendering
                unitLinkService: inject(unitLinkServiceKey),
            }
        },
        computed: {

            /**
             * @returns {StatusLabelConfig}
             */
            getTypeLabel() {
                return new StatusLabelConfig({
                    caption: UnitType.getByTypeName(this.revisionToDisplay.type).title
                });
            },

            /**
             * @returns {UnitRevision}
             */
            revisionToDisplay() {
                // Display latest released revision; or latest revision if unit can be updated
                const revisionToDisplay = this.unitIsEditable ?
                    this.unit.latestRevision :
                    this.unit.latestReleasedRevision;

                if (revisionToDisplay === null) {
                    throw Error(`No latest (released) revision found on unit ${this.unit.uid}.`);
                }

                return revisionToDisplay;
            },

            canDownloadTinCanPackage() {
                return this.$gate.allows(Permission.ability(Permission.UnitsGrantAccessViaLms()), this.unit);
            },

            canDownloadAiccPackage() {
                return (
                    this.course !== null
                    && this.featureRepository.active(Feature.FeatureAiccSupport)
                    && this.$gate.allows(Permission.ability(Permission.UnitsGrantAccessViaLms()), this.unit)
                );
            },

            canLaunchCourseUnit() {
                return this.unit.isReleased
                    && this.$gate.allows(Permission.ability(Permission.CoursesLaunchUnit()), this.course, this.unit)
                    && this.featureRepository.active(Feature.EntitlementDevicetypeWebapp);
            },

            canLaunchUnit() {
                return this.unit.isReleased
                    && this.$gate.allows(Permission.ability(Permission.UnitsLaunch()), this.unit)
                    && this.featureRepository.active(Feature.EntitlementDevicetypeWebapp);
            },

            unitIsEditable() {
                return this.$gate.allows(Permission.ability(Permission.UnitsUpdate()), this.unit);
            },
        },
        mounted() {
            this.$globalEvents.on(EventType.INSPECTOR_INSPECT, this.refresh);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.INSPECTOR_INSPECT, this.refresh);
        },
        methods: {
            trans,

            /**
             * Refresh the UI
             */
            refresh() {
                // Force re-render of the component:
                this.renderTimestamp = (new Date()).toString();
                return this;
            },

            /**
             * Error handler for API errors
             *
             * @param {String} error
             */
            onErrorApi(error) {
                this.$root.showErrorDialog(error);
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
