import ServiceIsBusyError from "@/Errors/ServiceIsBusyError";
import {route} from "@/Utility/Helpers";
import AxiosRequest from '@/Services/AxiosRequest';
import {AxiosResponse} from 'axios';

export default class ManageService {

    public isLoading: boolean = false;
    public isSaving: boolean = false;
    private request: AxiosRequest | null = null;

    /**
     * Cancel any ongoing requests.
     */
    async cancelRequests(): Promise<any> {
        // @NOTE: Only working with a single request at the moment!
        return this.request?.cancel();
    }

    async runCommand(commandName: string): Promise<string[]> {

        if (this.isLoading) {
            throw new ServiceIsBusyError();
        }

        this.isLoading = true;

        try {

            const url = route('api.manage.commands.run', {command: commandName});
            const response = await window.axios.post(url);
            return response.data.logs;

        } finally {
            this.isLoading = false;
        }
    }

    async updatePrivacyPolicy(value: string): Promise<string|null> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return this.request
            .post(
                route('api.manage.privacy_policy.update'),
                {
                    html: value,
                }
            )
            .then((response: AxiosResponse) => response.data)
            .catch(error => {
                console.error('ManageService->updatePrivacyPolicy():', error);
                throw error;
            })
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }
}
