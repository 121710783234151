<template>
    <span class="text-output-field">
        <Icon v-if="icon !== null" :name="icon"/>

        <span class="text">{{ text }}</span>

        <ButtonCircular
            class="small muted"
            v-tooltip="'labels.copy'"
            icon="icon_copy"
            @trigger="onCopy"
        />
    </span>
</template>

<script>

    import {Clipboard} from '@/Utility/Clipboard';

    export default {
        name: 'TextOutputField',
        props: {
            text: {
                type: String,
                default: null
            },
            icon: {
                type: String,
                default: null
            }
        },

        methods: {

            /**
             * Copies the fields text to the clipboard.
             * @param {Event} e
             */
            onCopy(e) {
                Clipboard.writeString(this.text);
                e.target.blur();
            },

        }
    }
</script>

<style scoped lang="scss">

    .text-output-field {
        display: flex;
        border: var(--forminput-border);
        border-radius: 16px;
        color: var(--color-primary);
        font-size: var(--font-size-medium);
        line-height: var(--line-height-medium);
        font-family: var(--font-family-condensed-demibold);
        padding: 6px 10px 6px 12px;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .icon {
            width: 18px;
            height: 18px;
            flex-basis: 18px;
        }

        .text {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .text-output-field + .text-output-field {
        margin-top: 8px;
    }

</style>
