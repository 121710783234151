<template>
    <tr>
        <td class="column-name-selection" colspan="2">
            <Dropdown
                :model="this"
                :options="options"
                :required="true"
                property="selectedName"
            />
        </td>
        <td colspan="2">
            <ButtonPrimary
                :caption="trans('labels.add_feature')"
                :disabled="!canSubmit"
                @trigger="onAddClicked"
            />
        </td>
    </tr>
</template>

<script lang="ts">
import DropdownOption from '@/Utility/DropdownOption';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {trans} from '@/Utility/Helpers.js';
import Dropdown from '@/Vue/Common/Dropdown.vue';
import {defineComponent} from 'vue';
import {Feature} from '@/Models/Features/Feature';

export default defineComponent({
    name: 'AddLocalFeatureForm',

    components: {
        Dropdown,
        ButtonPrimary
    },

    props: {
        featureNames: {
            type: Array,
            default: () => [],
        },
    },

    emits: [
        'click-add',
    ],

    data() {
        return {
            selectedName: null as string | null,
        };
    },

    computed: {

        options(): DropdownOption[] {
            return this.featureNames.map(
                name => new DropdownOption({
                    value: name,
                    caption: name,
                    title: Feature.getDescription(name),
                })
            );
        },

        canSubmit(): boolean {
            return this.selectedName !== null;
        },
    },

    methods: {
        trans,

        onAddClicked() {
            this.$emit('click-add', this.selectedName);
            this.selectedName = null;
        },

    }
});
</script>

<style lang="css" scoped>

td {
    padding-top: 16px;
    padding-right: 8px;
}

.btn {
    width: 100%;
}

</style>
