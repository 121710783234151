import {Permission} from '@/Models/User/Permission';
import {permission} from '@/Utility/Helpers';
import type User from '@/Models/User/User';
import type Tenant from '@/Models/Tenant/Tenant';
import {UserRole} from '@/Models/User/UserRole';

/**
 * Policy for checking user abilities and permissions.
 * Method names for abilities should map to permissions.
 *
 * e.g. Permission.TenantsUpdate() = 'tenants:update' => update() {}
 *
 * Policies can be registered in Gate instances:
 *
 * e.g. window.gate.policy('Tenant', new TenantPolicy);
 *
 * A registered policy can then be used via the gate. The gate chooses which policy to use based on the provided model
 * and the registered policies.
 *
 * e.g. window.gate.allows(Permission.ability(Permission.TenantsUpdate()), tenant);
 *
 */
export default class TenantPolicy {

    /**
     * Determine whether the user can read the tenant.
     */
    read(user: User, tenant: Tenant): boolean {
        if (permission(Permission.TenantsReadAny())) {
            return true;
        }

        if (!user.isCurrentTenant(tenant)) {
            return false;
        }

        return permission(Permission.TenantsRead());
    }

    impersonate_role_admin(_: User, tenant: Tenant): boolean {
        return tenant.is_default_tenant && permission(Permission.TenantsImpersonateRoleAdminInDefaultTenant()) ||
            tenant.is_default_admin_tenant && permission(Permission.TenantsImpersonateRoleAdminInDefaultAdminTenant()) ||
            tenant.is_default_asset_tenant && permission(Permission.TenantsImpersonateRoleAdminInDefaultAssetTenant()) ||
            !tenant.isAnyDefaultTenant && permission(Permission.TenantsImpersonateRoleAdmin());
    }

    /**
     * Determine whether the user can update the tenant.
     */
    update(user: User, tenant: Tenant): boolean {
        if (permission(Permission.TenantsUpdateAny())) {
            return true;
        }

        if (!user.isCurrentTenant(tenant)) {
            return false;
        }

        return permission(Permission.TenantsUpdate());
    }

    /**
     * Determine whether the user can delete the tenant.
     */
    delete(user: User, tenant: Tenant): boolean {
        if (tenant.isAnyDefaultTenant) {
            return false;
        }

        return permission(Permission.TenantsDeleteAny());
    }

    /**
     * Determine whether the logged-in user can read users that belong to the tenant.
     */
    read_users(user: User, tenant: Tenant): boolean {

        if (!user.isCurrentTenant(tenant)) {
            return false;
        }

        return permission(Permission.TenantsReadUsers());
    }

    remove_users(user: User, tenant: Tenant, userToRemove: User): boolean {
        if (!permission(Permission.TenantsRemoveUsers())) {
            return false;
        }

        if (!user.isCurrentTenant(tenant)) {
            return false;
        }

        return userToRemove.isRegularMemberOfTenant(tenant);
    }

    assign_role(user: User, tenant: Tenant, userToChange: User): boolean {
        if (userToChange.is_lms_user || userToChange.hasAnyRole(UserRole.WebApp)) {
            return false;
        }

        if (!permission(Permission.TenantsAssignRole())) {
            return false;
        }

        if (!user.isCurrentTenant(tenant)) {
            return false;
        }

        return userToChange.isRegularMemberOfTenant(tenant);
    }
}
