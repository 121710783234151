import {Permission} from '@/Models/User/Permission';
import FilterCategory from '@/Filters/FilterCategory';
import Asset from '@/Models/Asset/Asset';
import type User from '@/Models/User/User';
import {trans} from '@/Utility/Helpers';
import FeatureRepository from '@/Features/FeatureRepository';
import type Tenant from '@/Models/Tenant/Tenant';
import {Feature} from '@/Models/Features/Feature';

export default class AssetPermissionPolicy {

    static get Standard(): Readonly<AssetPolicyStandard> {
        return StaticAssetPolicyInstances.get(AssetPolicyStandard.type)!;
    }

    static get Sample(): Readonly<AssetPolicySample> {
        return StaticAssetPolicyInstances.get(AssetPolicySample.type)!;
    }

    static get Template(): Readonly<AssetPolicyTemplate> {
        return StaticAssetPolicyInstances.get(AssetPolicyTemplate.type)!;
    }

    static get UseOnly(): Readonly<AssetPolicyUseOnly> {
        return StaticAssetPolicyInstances.get(AssetPolicyUseOnly.type)!;
    }

    static get all(): Readonly<AssetPermissionPolicy>[] {
        return [...StaticAssetPolicyInstances.values()];
    }

    static get featureRepository(): FeatureRepository {
        return new FeatureRepository(window.features);
    }

    static allPoliciesUserIsAllowedToCreate(user: User): Readonly<AssetPermissionPolicy>[] {
        return AssetPermissionPolicy.all.filter(policy => user.permissions.find(permission => policy.canBeCreated && (permission === policy.createPermission)));
    }

    static allPoliciesUserIsAllowedToCreateInTenant(user: User, tenant: Tenant): Readonly<AssetPermissionPolicy>[] {
        return AssetPermissionPolicy
            .allPoliciesUserIsAllowedToCreate(user)
            .filter(policy => {
                return tenant.is_default_asset_tenant || !policy.hasToExistInsideAssetDefaultTenant;
            });
    }

    /**
     * Searches for an AssetPolicy with the desired type and returns its static instance.
     */
    static getAssetPolicyForType(type: string): Readonly<AssetPermissionPolicy> {
        return StaticAssetPolicyInstances.get(type) || new AssetPermissionPolicy();
    }

    static get type(): string {
        return 'undefined';
    }

    get type(): string {
        return (<typeof AssetPermissionPolicy>this.constructor).type;
    }

    get canBeCreated(): boolean {
        return false;
    }

    isEqualToPolicy(policy: AssetPermissionPolicy): boolean {
        return this.type === policy.type;
    }

    get createPermission(): string {
        throw new Error("Please override createPermission inside child class.");
    }

    get updatePermission(): string {
        throw new Error("Please override updatePermission inside child class.");
    }

    get deletePermission(): string {
        throw new Error("Please override deletePermission inside child class.");
    }

    get archivePermission(): string {
        throw new Error("Please override archivePermission inside child class.");
    }

    get restoreArchivedPermission(): string {
        throw new Error("Please override restoreArchivedPermission inside child class.");
    }

    get hasToExistInsideAssetDefaultTenant(): boolean {
        return true;
    }

    get canBeIndexedByForeignTenants(): boolean {
        return false;
    }

    get filterCategory(): FilterCategory {
        return new FilterCategory({
            title: trans('asset_policies.' + this.type),
            callback: (elements: any) => elements.filter((e: any) => e instanceof Asset && e.policy === this.type),
            paramName: this.type,
        });
    }
}

export class AssetPolicyStandard extends AssetPermissionPolicy {

    static get type(): string {
        return 'standard';
    }

    get canBeCreated(): boolean {
        return true;
    }

    get createPermission(): string {
        return Permission.AssetsCreatePolicyStandard();
    }

    get updatePermission(): string {
        return Permission.AssetsUpdatePolicyStandard();
    }

    get deletePermission(): string {
        return Permission.AssetsDeletePolicyStandard();
    }

    get archivePermission(): string {
        return Permission.AssetsArchivePolicyStandard();
    }

    get restoreArchivedPermission(): string {
        return Permission.AssetsRestoreArchivedPolicyStandard();
    }

    get hasToExistInsideAssetDefaultTenant(): boolean {
        return false;
    }
}

export class AssetPolicySample extends AssetPermissionPolicy {

    static get type(): string {
        return 'sample';
    }

    get canBeCreated(): boolean {
        return AssetPermissionPolicy.featureRepository.active(Feature.FeatureSharedTeamAssets);
    }

    get createPermission(): string {
        return Permission.AssetsCreatePolicySample();
    }

    get updatePermission(): string {
        return Permission.AssetsUpdatePolicySample();
    }

    get deletePermission(): string {
        return Permission.AssetsDeletePolicySample();
    }

    get archivePermission(): string {
        return Permission.AssetsArchivePolicySample();
    }

    get restoreArchivedPermission(): string {
        return Permission.AssetsRestoreArchivedPolicySample();
    }

    get hasToExistInsideAssetDefaultTenant(): boolean {
        return AssetPermissionPolicy.featureRepository.inactive(Feature.FeatureSharedTeamAssets);
    }

    get canBeIndexedByForeignTenants(): boolean {
        return true;
    }
}

export class AssetPolicyUseOnly extends AssetPermissionPolicy {

    static get type(): string {
        return 'use_only';
    }

    get canBeCreated(): boolean {
        return true;
    }

    get createPermission(): string {
        return Permission.AssetsCreatePolicyUseOnly();
    }

    get updatePermission(): string {
        return Permission.AssetsUpdatePolicyUseOnly();
    }

    get deletePermission(): string {
        return Permission.AssetsDeletePolicyUseOnly();
    }

    get archivePermission(): string {
        return Permission.AssetsArchivePolicyUseOnly();
    }

    get restoreArchivedPermission(): string {
        return Permission.AssetsRestoreArchivedPolicyUseOnly();
    }

    get canBeIndexedByForeignTenants(): boolean {
        return true;
    }
}

export class AssetPolicyTemplate extends AssetPermissionPolicy {

    static get type(): string {
        return 'template';
    }

    get canBeCreated(): boolean {
        return true;
    }

    get createPermission(): string {
        return Permission.AssetsCreatePolicyTemplate();
    }

    get updatePermission(): string {
        return Permission.AssetsUpdatePolicyTemplate();
    }

    get deletePermission(): string {
        return Permission.AssetsDeletePolicyTemplate();
    }

    get archivePermission(): string {
        return Permission.AssetsArchivePolicyTemplate();
    }

    get restoreArchivedPermission(): string {
        return Permission.AssetsRestoreArchivedPolicyTemplate();
    }
}

const StaticAssetPolicyInstances: Map<string, Readonly<AssetPermissionPolicy>> = new Map([
    [AssetPolicyStandard.type, Object.freeze(new AssetPolicyStandard())],
    [AssetPolicyUseOnly.type, Object.freeze(new AssetPolicyUseOnly())],
    [AssetPolicySample.type, Object.freeze(new AssetPolicySample())],
    [AssetPolicyTemplate.type, Object.freeze(new AssetPolicyTemplate())],
]);
