<template>
    <Modal
        id="dialog-save-or-dismiss-changes"
        ref="modal"
        :show-close-button="false"
        :title="trans('modals.save_or_dismiss_changes.title')"
        :user-closable="true"
        event-type="MODAL_SAVE_OR_DISMISS_CHANGES"
    >
        <slot>
            <p class="description" v-html="trans('modals.save_or_dismiss_changes.description')" />
        </slot>

        <template #buttons>
            <ButtonSecondary
                caption="modals.save_or_dismiss_changes.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonSecondary
                caption="modals.save_or_dismiss_changes.dismiss"
                class="btn-dismiss"
                @trigger="onClickDismiss"
            />
            <ButtonPrimary
                caption="modals.save_or_dismiss_changes.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import EventType from '@/Utility/EventType';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';
import {trans} from '@/Utility/Helpers';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';

export default defineComponent({
    components: {
        ButtonPrimary,
        ButtonSecondary,
        Modal
    },
    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    },
    methods: {
        trans,

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_SAVE_OR_DISMISS_CHANGES_APPLY);
        },

        onClickDismiss() {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_SAVE_OR_DISMISS_CHANGES_DISMISS);
        }
    }
});
</script>

<style lang="scss" scoped>

</style>
