import {Permission} from '@/Models/User/Permission';
import {permission} from '@/Utility/Helpers';
import type User from '@/Models/User/User';
import type Asset from '@/Models/Asset/Asset';
import AssetPermissionPolicy from '@/Models/Asset/AssetPermissionPolicy';

/**
 * Policy for checking user abilities and permissions.
 * Method names for abilities should map to permissions.
 *
 * e.g. Permission.AssetsUpdate() = 'assets:update' => update() {}
 *
 * Policies can be registered in Gate instances:
 *
 * e.g. window.gate.policy('Asset', new AssetPolicy);
 *
 * A registered policy can then be used via the gate. The gate chooses which policy to use based on the provided model
 * and the registered policies.
 *
 * e.g. window.gate.allows(Permission.ability(Permission.AssetsUpdate()), asset);
 *
 */
export default class AssetPolicy {

    /**
     * Determine whether the user can create any assets.
     */
    create(user: User): boolean {
        return (
            permission(Permission.AssetsCreate()) &&
            AssetPermissionPolicy.allPoliciesUserIsAllowedToCreate(user).length >= 1
        );
    }

    /**
     * Determine whether the user can archive the asset.
     */
    archive(user: User, asset: Asset): boolean {
        return (
            permission(asset.assetPolicy.archivePermission) &&
            this.assetIsOwnedByUserTenant(user, asset) &&
            !asset.archived
        );
    }

    /**
     * Determine whether the user can restore the archived the asset.
     */
    restore_archived(user: User, asset: Asset): boolean {
        return (
            permission(asset.assetPolicy.restoreArchivedPermission) &&
            this.assetIsOwnedByUserTenant(user, asset) &&
            asset.archived
        );
    }

    /**
     * Determine whether the user can update the asset.
     */
    update(user: User, asset: Asset): boolean {
        return (
            permission(asset.assetPolicy.updatePermission) &&
            this.assetIsOwnedByUserTenant(user, asset)
        );
    }

    /**
     * Determine whether the user can delete the asset.
     */
    delete(user: User, asset: Asset): boolean {
        return (
            permission(asset.assetPolicy.deletePermission) &&
            this.assetIsOwnedByUserTenant(user, asset)
        );
    }

    /**
     * Determine whether the user can add the asset to their library.
     */
    add_to_library(user: User, asset: Asset): boolean {
        return (
            permission(Permission.AssetsAddToLibrary()) &&
            !this.assetIsOwnedByUserTenant(user, asset) &&
            asset.assetPolicy.isEqualToPolicy(AssetPermissionPolicy.UseOnly) &&
            !asset.isInUserLibrary
        );
    }

    /**
     * Determine whether the user can remove the asset from their library.
     */
    remove_from_library(user: User, asset: Asset): boolean {
        return (
            permission(Permission.AssetsRemoveFromLibrary()) &&
            !this.assetIsOwnedByUserTenant(user, asset) &&
            asset.assetPolicy.isEqualToPolicy(AssetPermissionPolicy.UseOnly) &&
            asset.isInUserLibrary
        );
    }

    /**
     * @return true, if the asset is owned by the users current tenant
     */
    assetIsOwnedByUserTenant(user: User, asset: Asset): boolean {
        // Deny access to the asset if it is not owned by the current tenant
        return user.tenant !== null && asset.owned_by_tenant === user.tenant.uid;
    }
}
