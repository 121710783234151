import {Permission} from '@/Models/User/Permission';
import AssetPermissionPolicy, {
    AssetPolicySample,
    AssetPolicyStandard,
    AssetPolicyTemplate,
    AssetPolicyUseOnly
} from '@/Models/Asset/AssetPermissionPolicy';

export default class UnitPermissionPolicy {

    /**
     * Returns a unit policy for the specified permission.
     *
     * @param  {string} permission
     * @return {UnitPermissionPolicy|undefined}
     */
    static getPolicyForPermission(permission) {
        return PermissionPolicyMap.get(permission);
    }

    /**
     * @return {string[]}
     */
    static getUnitPolicyPermissions() {
        return [...PermissionPolicyMap.keys()];
    }

    /**
     * @return {[string, UnitPermissionPolicy][]}
     */
    static getPermissionPolicyMapping() {
        return [...PermissionPolicyMap.entries()];
    }

    /**
     * @param  {User}  user
     * @return {UnitPermissionPolicy[]}
     */
    static allAvailablePoliciesForUser(user) {
        return this.getPermissionPolicyMapping()
            .filter(mapping => user.permissions.find(permission => permission === mapping[0]))
            .map(mapping => mapping[1]);
    }

    /**
     * Returns a unit policy with the specified type.
     *
     * @param  {string} type
     * @return {UnitPermissionPolicy|undefined}
     */
    static getPolicyForType(type) {
        return StaticUnitPolicyInstances.get(type);
    }

    static get type() {
        return 'undefined';
    }

    get type() {
        return this.constructor.type;
    }

    get hasToExistInsideAssetDefaultTenant() {
        return true;
    }

    get canDuplicateInForeignTenant() {
        return false;
    }

    get canImportAsTemplate() {
        return false;
    }

    get duplicateWithStandardPolicy() {
        return true;
    }

    get policyForDuplicates() {
        return this.duplicateWithStandardPolicy ?
            UnitPermissionPolicy.getPolicyForType(UnitPermissionPolicyStandard.type) : this;
    }

    get canHavePublicLinksInForeignTenant() {
        return false;
    }

    get canBeUsedInLms() {
        return false;
    }

    /**
     * @return {AssetPermissionPolicy[]}
     */
    get allowedAssetPolicies() {
        return AssetPermissionPolicy.all;
    }

    /**
     * @param {string} assetPolicyType
     * @return {boolean}
     */
    isAssetPolicyAllowed(assetPolicyType) {
        return this.allowedAssetPolicies.some(allowedPolicy => allowedPolicy.type === assetPolicyType);
    }
}

export class UnitPermissionPolicyStandard extends UnitPermissionPolicy {

    static get type() {
        return 'standard';
    }

    get hasToExistInsideAssetDefaultTenant() {
        return false;
    }

    get canDuplicateInForeignTenant() {
        return true;
    }

    get canBeUsedInLms() {
        return true;
    }

    get allowedAssetPolicies() {
        return [
            AssetPolicyStandard,
            AssetPolicySample,
            AssetPolicyUseOnly,
        ];
    }
}

export class UnitPermissionPolicySample extends UnitPermissionPolicy {

    static get type() {
        return 'sample';
    }

    get canDuplicateInForeignTenant() {
        return true;
    }

    get canHavePublicLinksInForeignTenant() {
        return true;
    }

    get canBeUsedInLms() {
        return true;
    }

    get allowedAssetPolicies() {
        return [
            AssetPolicySample,
        ];
    }
}

export class UnitPermissionPolicyTemplate extends UnitPermissionPolicy {

    static get type() {
        return 'template';
    }

    get canImportAsTemplate() {
        return true;
    }

    get duplicateWithStandardPolicy() {
        return false;
    }

    get allowedAssetPolicies() {
        return [
            AssetPolicySample,
            AssetPolicyUseOnly,
            AssetPolicyTemplate,
        ];
    }
}

/**
 * Map of UnitPolicies and their types
 *
 * @type {Map<string, UnitPermissionPolicy>}
 */
const StaticUnitPolicyInstances = new Map([
    [UnitPermissionPolicySample.type, Object.freeze(new UnitPermissionPolicySample())],
    [UnitPermissionPolicyTemplate.type, Object.freeze(new UnitPermissionPolicyTemplate())],
    [UnitPermissionPolicyStandard.type, Object.freeze(new UnitPermissionPolicyStandard())],
]);

/**
 * Map of Permissions and associated UnitPolicies
 *
 * @type {Map<string, UnitPermissionPolicy|undefined>}
 */
const PermissionPolicyMap = new Map([
    [Permission.UnitsAccessPolicySample(), UnitPermissionPolicy.getPolicyForType(UnitPermissionPolicySample.type)],
    [Permission.UnitsAccessPolicyTemplate(), UnitPermissionPolicy.getPolicyForType(UnitPermissionPolicyTemplate.type)],
    [Permission.UnitsAccessPolicyStandard(), UnitPermissionPolicy.getPolicyForType(UnitPermissionPolicyStandard.type)],
]);
