import {Permission} from '@/Models/User/Permission';
import {permission} from '@/Utility/Helpers';
import type User from '@/Models/User/User';
import type Unit from '@/Models/Unit/Unit';
import type UnitLink from '@/Models/Unit/UnitLink';

/**
 * Policy for checking user abilities and permissions.
 * Method names for abilities should map to permissions.
 *
 * e.g. Permission.UnitLinksCreate() = 'unit_links:create' => create() {}
 *
 * Policies can be registered in Gate instances:
 *
 * e.g. window.gate.policy('UnitLink', new UnitLinkPolicy);
 *
 * A registered policy can then be used via the gate. The gate chooses which policy to use based on the provided model
 * and the registered policies.
 *
 * e.g. window.gate.allows(Permission.ability(Permission.UnitLinksCreate()), UnitLink.constructorName, unit);
 *
 */
export default class UnitLinkPolicy {

    /**
     * Determine whether the user can read the unit.
     */
    create(user: User, unit: Unit): boolean {
        return (
            unit.isReleased
            && this.unitIsInScopeForReading(user, unit)
            && permission(Permission.UnitLinksCreate())
        );
    }

    /**
     * Determine whether the user can read the unit.
     */
    read(user: User, unit: Unit): boolean {
        if (!this.unitIsInScopeForReading(user, unit)) {
            return false;
        }

        if (permission(Permission.UnitLinksRead())) {
            return true;
        }

        return false;
    }

    /**
     * Determine whether the user can update the unit.
     */
    update(user: User, unitLink: UnitLink): boolean {
        const unitLinkTenantIsCurrentTenant = user.tenant?.uid === unitLink.tenant_uid;

        if (!unitLinkTenantIsCurrentTenant) {
            return false;
        }

        if (permission(Permission.UnitLinksUpdateAny())) {
            return true;
        }

        if (unitLink.owned_by === user.uid && permission(Permission.UnitLinksUpdateOwn())) {
            return true;
        }

        return false;
    }

    /**
     * Determine whether the user can delete the unit.
     */
    delete(user: User, unitLink: UnitLink): boolean {
        const unitLinkTenantIsCurrentTenant = user.tenant?.uid === unitLink.tenant_uid;

        if (!unitLinkTenantIsCurrentTenant) {
            return false;
        }

        if (permission(Permission.UnitLinksDeleteAny())) {
            return true;
        }

        if (unitLink.owned_by === user.uid && permission(Permission.UnitLinksDeleteOwn())) {
            return true;
        }

        return false;
    }

    unitIsInScopeForReading(user: User, unit: Unit): boolean {
        // Every unit passed here comes from the backend and is validated
        // to be readable by the user.

        // Unreleased units cannot handle public links
        if (!unit.isReleased) {
            return false;
        }

        const unitOwnerIsCurrentTenant = user.tenant?.uid === unit.owned_by_tenant;

        // Some policies do not allow for public links in foreign tenants
        if (!unitOwnerIsCurrentTenant && !unit.parsedPolicy.canHavePublicLinksInForeignTenant) {
            return false;
        }

        return true;
    }
}
