<template>
    <div class="widget-model-animations">
        <Icon name="icon_close" class="icon-close" @click="$emit('hide')"/>

        <div class="widget-model-animations-header">
            <h3>{{ trans('labels.animations') }}</h3>
        </div>

        <div class="widget-model-animations-body">
            <p v-if="animations === null || animations.length === 0">{{ trans('labels.no_animations_available') }}</p>
            <ul v-else class="list-animations">
                <li v-for="animation in animations"
                    @click="onClickCopyAnimationName(animation.name, $event)"
                    @mouseover="onMouseOver(animation)"
                    @mouseout="onMouseOut(animation)">
                    <ButtonCircular
                        class="small"
                        v-tooltip="'labels.copy'"
                        icon="icon_copy"
                        :caption="animation.name"
                        @trigger="onClickCopyAnimationName(animation.name, $event)"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script lang="ts">
import type { PropType} from 'vue';
import {defineComponent} from 'vue';
import Icon from '@/Vue/Common/Icon.vue';
import {trans} from '@/Utility/Helpers';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import type {AnimationClip} from 'three';
import {Clipboard} from '@/Utility/Clipboard';

export default defineComponent({
    components: {
        ButtonCircular,
        Icon
    },

    props: {
        animations: {
            type: Array as PropType<AnimationClip[] | null>,
            default: null,
        },
    },

    methods: {
        trans,

        onClickCopyAnimationName(animationName: string, e: Event) {
            Clipboard.writeString(animationName);
            (e.target as HTMLElement | null)?.blur();
        },
        onMouseOver(animation: AnimationClip) {
            this.$emit('mouseOverAnimation', animation);
        },
        onMouseOut(animation: AnimationClip) {
            this.$emit('mouseOutAnimation', animation);
        },
    }
});
</script>

<style lang="scss">
.widget-model-animations {
    position: absolute;
    top: 20px;
    left: 20px;
    min-width: 300px;
    max-width: 50%;
    min-height: 150px;
    max-height: calc(100% - 110px);
    padding: 0 0 20px;
    background-color: #ffffff;
    border-radius: var(--card-border-radius);
    overflow: hidden;

    display: flex;
    flex-direction: column;

    & > .icon-close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 15px;
        height: 15px;
        cursor: pointer;

        &:hover {
            color: var(--color-primary-hover);
        }
    }
}

.widget-model-animations-header {
    padding: 15px 20px 10px 20px;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.widget-model-animations-body {
    padding: 0 30px 0 20px;
    overflow: auto;

    ul {
        list-style-type: none;
        width: min-content;
        margin-bottom: 0 !important;
    }

    .list-animations li {
        list-style-type: square;
        list-style-position: inside;
        white-space: nowrap;

        .btn {
            display: inline-block;
            width: max-content;
            font-family: var(--font-family-default);
            text-transform: none;

            svg {
                float: right;
                margin: -1px 0 0 8px;
                color: var(--color-primary-hover);
                visibility: hidden;
                transition: transform 0.1s cubic-bezier(0.390, 0.575, 0.565, 1.000);
            }

            &:hover svg {
                visibility: visible;
            }

            &:active svg {
                transform: scale(1.1);
            }
        }
    }
}

</style>
