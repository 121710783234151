<template>

    <section>
        <form :action="forRoute" :method="formMethod">
            <header>
                <h3 class="title">
                    {{ title }}
                </h3>
                <p v-if="subtitle">
                    {{ subtitle }}
                </p>
            </header>

            <input :value="csrf" name="_token" type="hidden">

            <!-- Identifier (E-Mail) -->
            <main v-if="isIdentifierMode">

                <div class="field">
                    <label for="email">{{ trans('labels.email') }}</label>
                    <input
                        id="email"
                        ref="email"
                        :placeholder="trans('labels.email')"
                        :value="identifier"
                        autocomplete="email"
                        autofocus
                        name="email"
                        required
                        type="email"
                    >
                </div>

                <div class="errors">
                    <p v-for="message in errorMessages" :key="message" class="error">
                        {{ message }}
                    </p>
                </div>

                <ButtonPrimary :caption="trans('labels.proceed')" :prevent-default="false" />
            </main>

            <!-- E-Mail + Password -->
            <main v-else>

                <div class="field">
                    <label for="email">{{ trans('labels.email') }}</label>
                    <input
                        id="email"
                        :placeholder="trans('labels.email')"
                        :value="identifier"
                        autocomplete="email"
                        name="email"
                        readonly
                        required
                        type="email"
                    >
                    <ButtonCircular
                        :title="trans('labels.edit')"
                        class="small"
                        icon="icon_edit"
                        type="button"
                        @trigger="editIdentifier"
                    />
                </div>

                <div class="field">
                    <label for="password">{{ trans('labels.password') }}</label>
                    <input
                        id="password"
                        ref="password"
                        :placeholder="trans('labels.password')"
                        :type="showPassword ? 'text' : 'password'"
                        autocomplete="current-password"
                        autofocus
                        name="password"
                        required
                    >
                    <ButtonCircular
                        :icon="showPassword ? 'icon_hide_outline' : 'icon_show_outline'"
                        :title="trans('labels.view')"
                        class="small"
                        type="button"
                        @trigger="togglePasswordVisibility"
                    />
                </div>

                <div class="errors">
                    <p v-for="message in errorMessages" :key="message" class="error">
                        {{ message }}
                    </p>
                </div>

                <fieldset class="meta">
                    <a v-if="showForgotPasswordLink" :href="route('password.request')" class="forgot-password">
                        {{ trans('auth.forgot_password_link') }}
                    </a>

                    <div v-if="showRememberMeCheckbox" class="checkbox-wrapper">
                        <input
                            id="remember"
                            v-model="rememberMe"
                            name="remember"
                            true-value="on"
                            type="checkbox"
                        >
                        <label for="remember">{{ trans('auth.remember_me') }}</label>
                    </div>
                </fieldset>

                <ButtonPrimary :caption="trans('labels.login')" :prevent-default="false" />
            </main>

            <!-- Privacy Policy -->
            <footer v-if="showPrivacyPolicy">
                <h4>{{ trans('labels.privacy_policy.headline') }}</h4>
                <p>
                    {{ trans('labels.privacy_policy.text') }}
                    <a
                        class="link-external"
                        href="#"
                        @click="onClickPrivacyPolicy"
                    >
                        {{ trans('labels.privacy_policy.link') }}
                    </a>
                </p>
            </footer>

            <!-- What's new? -->
            <footer v-else-if="showWhatsNewFooter">
                <h4>{{ trans('labels.feature_timeline.headline') }}</h4>
                <p>{{ trans('labels.feature_timeline.text') }}</p>
                <a
                    :href="timelineUrl"
                    class="link-external"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {{ trans('labels.feature_timeline.button') }}
                </a>
            </footer>
        </form>

        <StatusLabel :config="versionLabelConfig" />
    </section>

</template>

<script lang="ts">
import type {PropType} from 'vue';
import {defineComponent} from 'vue';
import {route, trans} from '@/Utility/Helpers';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import StatusLabel from '@/Vue/Common/StatusLabel.vue';
import StatusLabelConfig from '@/Utility/StatusLabelConfig';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import appdata from '@/Tests/__mocks__/appdata';
import EventType from '@/Utility/EventType';

type Mode = 'identifier' | 'password';

export default defineComponent({
    components: {
        ButtonCircular,
        StatusLabel,
        ButtonPrimary,
    },

    props: {
        csrf: {
            type: String,
            required: true,
        },
        initialMode: {
            type: String as PropType<Mode>,
            default: 'identifier'
        },
        initialIdentifier: {
            type: String as PropType<string | null>,
            default: null,
        },
        initialRememberMe: {
            type: String,
            default: '',
        },
        initialErrorMessages: {
            type: Array as PropType<string[]>,
            default() {
                return [];
            }
        },
        showPrivacyPolicy: {
            type: Boolean,
            default: false,
        },
        showWhatsNewFooter: {
            type: Boolean,
            default: false,
        },
        showForgotPasswordLink: {
            type: Boolean,
            default: true,
        },
        showRememberMeCheckbox: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            mode: this.initialMode,
            identifier: this.initialIdentifier,
            rememberMe: this.initialRememberMe,
            errorMessages: this.initialErrorMessages,
            showPassword: false,
        };
    },

    computed: {
        appdata() {
            return appdata;
        },

        isIdentifierMode() {
            return this.mode === 'identifier';
        },

        forRoute() {
            return this.isIdentifierMode ?
                route('auth.provider.get') :
                route('login');
        },

        formMethod() {
            return this.isIdentifierMode ? 'get' : 'post';
        },

        title() {
            return this.isIdentifierMode ?
                trans('auth.headline_identifier') :
                trans('auth.headline_password');
        },

        subtitle() {
            return this.isIdentifierMode ?
                trans('auth.subline_identifier') :
                trans('auth.subline_password');
        },

        versionLabelConfig(): StatusLabelConfig {
            return new StatusLabelConfig({
                caption: window.appData.APP_RELEASE_VERSION,
            });
        },

        timelineUrl() {
            return window.appData.FEATURES_TIMELINE_URL;
        },
    },

    methods: {
        route,
        trans,

        editIdentifier() {
            this.mode = 'identifier';
            this.errorMessages = [];

            this.$nextTick(this.selectTextInEmailField);
        },

        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },

        selectTextInEmailField() {
            const emailField = this.$refs.email as HTMLInputElement;
            emailField.select();
        },

        onClickPrivacyPolicy() {
            this.$globalEvents.emit(EventType.MODAL_PRIVACY_POLICY_SHOW);
        }
    }
});
</script>

<style lang="css" scoped>

section {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;

    background: transparent url('/images/background_login.jpg') no-repeat scroll left top;
    background-size: cover;

    form {
        width: 480px;
        padding: var(--modal-padding);
        background-color: var(--background-color-white);
        border-radius: var(--card-border-radius);
        box-shadow: var(--card-box-shadow);
        z-index: 5;

        header {
            margin-bottom: 24px;
        }

        main {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .field {
                position: relative;

                label {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    padding: 0 6px;
                    color: var(--color-anthracite80);
                    background-color: var(--background-color-white);
                    border-radius: 4px;
                    transition: transform ease-in-out 0.1s;

                    /* Fix for older browsers that don't support :has */
                    transform: scale(0.9) translateY(-110%);
                }

                /* Fix for older browsers that don't support :has */
                &:has(input) > label {
                    transform: none;
                }

                /* label for focused or filled input fields */

                &:has(input:not(:placeholder-shown)) > label,
                &:has(input:focus-visible) > label {
                    transform: scale(0.9) translateY(-110%);
                }

                /* label for focused fields */

                &:has(input:focus-visible:not(:read-only)) > label {
                    color: var(--color-primary-hover);
                }

                /* label for invalid fields */

                &:has(input:user-invalid) > label {
                    color: var(--color-signal);
                }

                #password[type="text"] {
                    /* revealed password should be readable */
                    font-family: var(--font-family-mono-regular);
                    font-size: 15px;
                }

                input[type="email"],
                input[type="text"],
                input[type="password"] {
                    width: 100%;
                    appearance: none;
                    height: 42px;
                    padding: 0 16px;
                    border: var(--forminput-border);
                    border-radius: var(--forminput-border-radius);
                    transition: border-color 0.1s;
                    font-size: 16px;

                    /* hide placeholder - we use the label instead */

                    &::placeholder {
                        color: transparent;
                        -webkit-text-fill-color: transparent;
                    }

                    &:focus-visible:not(:read-only) {
                        border-color: var(--color-primary-hover);
                    }

                    &:user-invalid {
                        border-color: var(--color-signal);
                    }

                    &:read-only {
                        background-color: var(--color-anthracite05);
                        border-color: var(--color-anthracite05);
                    }

                    & + .btn-circular.small {
                        position: absolute;
                        top: 9px;
                        right: 9px;
                    }
                }

                ::-ms-reveal {
                    /* hide system reveal password button in edge */
                    display: none;
                }
            }

            .checkbox-wrapper {
                display: flex;
                gap: 8px;
                align-items: center;
            }

            .errors {
                color: var(--color-signal);

                &:empty {
                    display: none;
                }
            }

            fieldset.meta {
                display: flex;
                justify-content: space-between;

                &:empty {
                    display: none;
                }
            }

            & > button {
                height: 40px;
                margin-top: 8px;
                align-self: end;
                border-radius: 20px;
            }
        }

        footer {
            margin-top: 16px;
            padding-top: 16px;
            border-top: var(--forminput-border);
        }
    }

    .status-label {
        position: absolute;
        top: 3vw;
        right: 2vw;
    }
}

/**
 * Small viewports will be used by mobile screens and the in-app browser.
 * We omit the background and make the whole form fullscreen.
 */
@media (max-width: 500px) {
    section {
        align-items: start;
        background: var(--background-color-white);

        form {
            width: 100%;
            box-shadow: none;

            main > button {
                width: 100%;
            }
        }

        .status-label {
            display: none;
        }
    }
}

</style>

<style>
    #modal-privacy-policy {
        width: 95vw;
        max-width: 1000px;
        max-height: 95vh;
        overflow: unset;

        .scrollable-text {
            overflow: auto;
            max-height: calc(100vh - 170px);
        }

        &.is-app {
            width: 100% !important;
            height: 100% !important;
            max-width: 100% !important;
            max-height: 100% !important;
            margin: 0 !important;
            border-radius: 0 !important;

            .scrollable-text {
                max-height: calc(100vh - 60px);
            }

            .close-button {
                border-radius: 0;
            }
        }
    }
</style>
