<template>
    <div class="unit-preview-overlay">
        <h2>{{ title }}</h2>
        <p v-if="description">
            {{ description }}
        </p>
        <TextInput
            v-if="requiresPassword"
            :model="form"
            :placeholder="trans('units.launch.preview_overlay.password.placeholders.password')"
            class="no-wrap"
            icon="icon_copy"
            property="password"
            type="password"
            :validation-errors="passwordErrors"
            @change="onPasswordChanged"
        />
        <ButtonPrimary
            :caption="buttonCaption"
            :class="{'icon-only': buttonCaption === null }"
            :disabled="buttonDisabled"
            :icon="buttonIcon"
            @trigger="onClick"
        />
    </div>
</template>

<script lang="ts">

import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import type {PropType} from 'vue';
import {defineComponent} from 'vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import {trans} from '@/Utility/Helpers';

export default defineComponent({
    components: {
        TextInput,
        ButtonPrimary,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String as PropType<string | null>,
            required: true,
        },
        buttonCaption: {
            type: String as PropType<string | null>,
            required: true,
        },
        buttonIcon: {
            type: String as PropType<string | null>,
            required: true,
        },
        buttonDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        requiresPassword: {
            type: Boolean,
            required: false,
            default: false,
        },
        errors: {
            type: Array,
            required: false,
            default: function() {
                return [];
            },
        },
    },

    emits: [
        'click',
        'remove-errors',
    ],

    data() {
        return {
            form: {
                password: null as string | null,
            },
        };
    },

    computed: {
        passwordErrors() {
            return this.validationErrors('password');
        },
    },

    methods: {
        trans,

        onClick() {
            if (this.requiresPassword) {
                this.$emit('click', this.form.password);
                return;
            }

            this.$emit('click');
        },

        validationErrors(property: string): Array<string> {
            return this.errors && Object.prototype.hasOwnProperty.call(this.errors, property) ? this.errors[property] : [];
        },

        onPasswordChanged() {
            this.$emit('remove-errors');
        },
    },
});
</script>

<style scoped>
.unit-preview-overlay {
    flex-basis: 500px;
    display: flex;
    flex-direction: column;
    border: none;
    padding: 32px;
    background-color: var(--background-color-white);
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-box-shadow);
    z-index: 1;

    p {
        margin-bottom: 0;
    }

    h2 + button {
        margin-top: 16px;
    }

    .btn {
        width: 100%;
        min-height: 35px;
    }

    &:deep(.btn.icon-only svg.icon) {
        margin-right: 0;
    }

    &:deep(.type-password) {
        margin-top: var(--forminput-spacing);
    }
}

</style>
