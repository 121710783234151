<template>
    <main id="layout-main" :data-loading="isLoading" :data-saving="isSaving">

        <PageHeader
            :page-title="trans('users.index.headline_team', {team: currentUser!.tenant?.name || ''})"
            :buttons="headerButtons"
            @button-click="onHeaderButtonClick"
        />

        <div id="layout-content">
            <div id="content" v-not-focusable>
                <div class="container list-filters-wrapper">

                    <div class="list-filters">

                        <ButtonPrimary
                            v-if="canCreateUsers"
                            :href="route('users.createForm')"
                            caption="labels.create_user"
                            class="btn-add-new"
                            icon="icon_add"
                        />

                        <Tabs
                            v-if="canManageGroups"
                            class="list-filters-categories"
                            :tabs="Object.values(tabs)"
                        />
                    </div>
                </div>

                <div v-if="!canManageGroups || tabs.users.isActive" class="container user-list">
                    <UserListItem
                        v-for="user in users"
                        :key="user.uid"
                        v-focusable
                        :user="user"
                        :tenant="currentUser.tenant!"
                        @click-remove="onClickRemoveUser"
                        @click-delete="onClickDeleteUser"
                    />
                </div>

                <div v-if="canManageGroups && tabs.groups.isActive" class="container group-list">
                    <ManageGroupRoles :tenant-roles="tenantRolesInstances" />
                </div>
            </div>

            <!-- Attention: teleport target for ManageGroupRoles component -->
            <aside
                id="layout-inspector"
                :class="{
                    open: canAddUsersToTeam && tabs.users.isActive || canManageGroups && tabs.groups.isActive
                }"
            >
                <AddUserToTenant v-if="canAddUsersToTeam && tabs.users.isActive" :tenant-roles="tenantRolesInstances" />
            </aside>

            <ModalProgress />
            <ModalNotification />

            <ModalRemoveUserFromTenant />
            <ModalDeleteUser />
            <ModalChangeTenantRole :tenant-roles="tenantRolesInstances" />

            <ModalImportUserCsv />
            <ModalImportUsers />

        </div>
    </main>
</template>

<script lang="ts">

    import EventType from '@/Utility/EventType';
    import AuthorizationError from '@/Errors/AuthorizationError';
    import {permission, route, trans} from '@/Utility/Helpers';
    import {Permission} from '@/Models/User/Permission';
    import type { PropType} from 'vue';
    import {defineComponent, inject} from 'vue';
    import {featureRepositoryKey, tenantServiceKey, userServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
    import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
    import PageHeader from '@/Vue/Common/PageHeader.vue';
    import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
    import UserListItem from '@/Vue/Users/UserListItem.vue';
    import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
    import PageHeaderButton from '@/Utility/PageHeaderButton';
    import ModalImportUserCsv from '@/Vue/Modals/ModalSelectImportUserCsv.vue';
    import ModalImportUsers from '@/Vue/Modals/ModalImportUsers.vue';
    import AddUserToTenant from '@/Vue/Users/AddUserToTenant.vue';
    import {Feature} from '@/Models/Features/Feature';
    import type User from '@/Models/User/User';
    import ModalDeleteUser from '@/Vue/Modals/ModalDeleteUser.vue';
    import Tabs from '@/Vue/Common/Tabs.vue';
    import Category from '@/Filters/Category';
    import ManageGroupRoles from '@/Vue/Users/ManageGroupRoles.vue';
    import {TenantRole} from '@/Models/Tenant/TenantRole';
    import ModalRemoveUserFromTenant from '@/Vue/Modals/ModalRemoveUserFromTenant.vue';
    import ModalChangeTenantRole from '@/Vue/Modals/ModalChangeTenantRole.vue';

    export default defineComponent({

        components: {
            ModalChangeTenantRole,
            ModalRemoveUserFromTenant,
            Tabs,
            ModalDeleteUser,
            AddUserToTenant,
            ModalImportUsers,
            ModalImportUserCsv,
            ModalProgress,
            UserListItem,
            ButtonPrimary,
            PageHeader,
            ModalNotification,
            ManageGroupRoles,
        },

        props: {
            tenantRoles: {
                type: Array as PropType<TenantRole[]>,
                default() {
                    return [];
                }
            },
        },

        data() {
            return {
                userService: inject(userServiceKey)!,
                tenantService: inject(tenantServiceKey)!,
                featureRepository: inject(featureRepositoryKey)!,
                isSubmitting: false,
                tabs: {
                    users: new Category({
                        title: trans('labels.users'),
                    }),
                    groups: new Category({
                        title: trans('labels.group_permissions'),
                    }),
                },
                events: new Map([
                    [EventType.SIDEPANEL_ADD_USER_TO_TENANT_ADDED, this.fetchUsers],
                    [EventType.MODAL_REMOVE_USER_APPLY, this.onApplyConfirmRemove],
                    [EventType.MODAL_DELETE_USER_APPLY, this.onApplyConfirmDelete],
                    [EventType.MODAL_CHANGE_TENANT_ROLE_APPLY, this.onApplyChangeTenantRole],
                ]),
            }
        },

        watch: {
            'tabs.users.isActive': function (isActive: boolean) {
                if (isActive) {
                    // reload user list when tab is focused to fetch group changes
                    this.fetchUsers();
                }
            }
        },

        mounted() {
            if (!this.canManageGroups) {
                this.fetchUsers();
            }

            this.events.forEach((value, key) => {
                this.$globalEvents.on(key, value);
            });
        },

        beforeUnmount() {
            this.events.forEach((value, key) => {
                this.$globalEvents.off(key, value);
            });
        },

        computed: {

            tenantRolesInstances(): TenantRole[] {
                return this.tenantRoles.map(role => new TenantRole(role));
            },

            currentUser() {
                return window.currentUser!;
            },

            canAddUsersToTeam() {
                return (
                    this.featureRepository.active(Feature.FeatureInviteUsersToTeam)
                    && (
                        permission(Permission.TenantsAddUsers())
                        || permission(Permission.TenantInvitationsCreate())
                    )
                );
            },

            canCreateUsers() {
                return permission(Permission.UsersCreate());
            },

            canExportUsers() {
                return permission(Permission.UsersExport());
            },

            canImportUsers() {
                return permission(Permission.UsersImport());
            },

            canManageGroups() {
                return permission(Permission.TenantsManageGroups());
            },

            users() {
                return this.userService.users;
            },

            isLoading(): boolean {
                if (this.userService.isLoading || this.tenantService.isLoading) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            },

            isSaving(): boolean {
                if (this.userService.isSaving || this.tenantService.isSaving) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            },

            /**
             * List of button configurations for the page header
             */
            headerButtons() {
                return {
                    importUsers: new PageHeaderButton({
                        disabled: this.isLoading,
                        visible: this.canImportUsers,
                        caption: trans('labels.import'),
                        icon: 'icon_import',
                        style: 'icon+text',
                        callback: this.importUserCSV,
                    }),
                    exportUsers: new PageHeaderButton({
                        disabled: this.isLoading,
                        visible: this.canExportUsers,
                        caption: trans('labels.export'),
                        icon: 'icon_export',
                        style: 'icon+text',
                        callback: this.downloadExportedUsersCSV,
                    }),
                };
            },
        },

        methods: {
            trans,
            route,

            fetchUsers() {
                this.userService
                    .fetchUsers()
                    .catch(this.onErrorApi);
            },

            onErrorApi(error: any) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError) {
                    error.callback = this.$root?.forceLogout;
                }

                this.$root?.showErrorDialog(error);
            },

            onHeaderButtonClick(buttonConfig: PageHeaderButton) {
                buttonConfig.callback?.call(this, buttonConfig);
            },

            importUserCSV() {
                this.$globalEvents.emit(EventType.MODAL_SELECT_IMPORT_USER_CSV_SHOW);
            },

            downloadExportedUsersCSV() {
                if (this.canExportUsers) {
                    this.userService
                        .downloadExportedUsersCSV()
                        .catch(this.onErrorApi);
                }
            },

            onClickRemoveUser(user: User) {
                if (this.isSubmitting) {
                    return;
                }

                this.$globalEvents.emit(EventType.MODAL_REMOVE_USER_SHOW, user, window.currentUser!.tenant!);
            },

            onClickDeleteUser(user: User) {
                if (this.isSubmitting) {
                    return;
                }

                this.$globalEvents.emit(EventType.MODAL_DELETE_USER_SHOW, user);
                return this;
            },

            onApplyConfirmRemove(user: User) {
                if (this.tenantService.isDeleting || this.tenantService.isLoading || this.tenantService.isSaving) {
                    return;
                }

                this.isSubmitting = true;
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.removing_user'));

                this.tenantService.removeUser(window.currentUser!.tenant!, user)
                    .then(() => {
                        // Force logout if the current user was removed, otherwise redirect to users list:
                        window.location.href = route((user.uid === window.currentUser?.uid) ? 'logout' : 'users.index');
                    })
                    .catch((error) => {
                        this.isSubmitting = false;
                        this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                        this.$root!.showErrorDialog(error);
                    });
            },

            onApplyConfirmDelete(user: User) {
                if (this.tenantService.isDeleting || this.tenantService.isLoading || this.tenantService.isSaving) {
                    return;
                }

                this.isSubmitting = true;
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.deleting_user'));

                this.userService.deleteUser(user.uid)
                    .then(() => {
                        // Force logout if the current user was deleted, otherwise redirect to users list:
                        window.location.href = route((user.uid === window.currentUser?.uid) ? 'logout' : 'users.index');
                    })
                    .catch((error) => {
                        this.isSubmitting = false;
                        this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                        this.$root!.showErrorDialog(error);
                    });
            },

            onApplyChangeTenantRole(user: User) {
                this.$toast.success(trans('modals.change_tenant_role.success', {
                    name: user.fullName,
                    role: user.tenant_role!.caption,
                }));
                if (user.uid === this.currentUser.uid) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                    if (user.tenant_role!.name === 'learner') {
                        window.location.href = '/';
                    }else{
                        window.location.reload();
                    }
                }
            },
        },
    });
</script>

<style lang="scss" scoped>
    .user-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 30px;
    }

    #layout-main {
        :deep(.add-user-to-tenant) {
            max-width: 450px;
        }
    }
</style>
