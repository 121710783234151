<template>
    <div id="inspector" v-not-focusable class="inspector-content">
        <InspectorHeader
            :title="trans('labels.inspector.course_properties')"
            icon-name="icon_courses"
        />

        <!-- Panels for editing object properties -->
        <div v-not-focusable class="panels">
            <div v-if="course" :key="'PanelViewCourse'+course.uid" class="inspector-panel panel-view-course">

                <!-- Preview image -->
                <InspectorItemPreviewImage
                    :editable="false"
                    :src="course.preview"
                />

                <!-- Title -->
                <div class="property property-title">
                    <TextInput
                        :disabled="true"
                        :error-msg="trans('errors.course.title')"
                        :maxlength="50"
                        :model="course"
                        :required="true"
                        property="title"
                    />
                </div>

                <!-- Description -->
                <div v-if="course.description" class="property property-description">
                    <TextInput
                        :disabled="true"
                        :maxlength="600"
                        :model="course"
                        :required="false"
                        property="description"
                        type="textarea"
                    />
                </div>

                <PanelUserEnrollment
                    v-if="showUserEnrollments && isAllowedToEnrollUsers"
                    :course="course"
                />

            </div>

        </div>
    </div>
</template>

<script lang="ts">
import InspectorItemPreviewImage from '@/Vue/Inspector/Items/InspectorItemPreviewImage.vue';
import PanelUserEnrollment from '@/Vue/Inspector/PanelUserEnrollment.vue';
import Course from '@/Models/Course/Course';
import {trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import TextInput from '@/Vue/Common/TextInput.vue';
import {defineComponent} from 'vue';
import InspectorHeader from '@/Vue/Inspector/InspectorHeader.vue';


export default defineComponent({

    components: {
        InspectorHeader,
        TextInput,
        InspectorItemPreviewImage,
        PanelUserEnrollment,
    },

    props: {
        course: {
            type: Course,
            default: null,
        },
        showUserEnrollments: {
            type: Boolean,
            default: false,
        },
    },

    computed: {

        isAllowedToEnrollUsers() {
            return this.$gate.allows(Permission.CoursesEnrollUsers());
        },
    },

    methods: {
        trans
    }
});
</script>

<style lang="scss" scoped>

</style>
