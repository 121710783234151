<template>
    <Modal
        ref="modal"
        :animated="true"
        :user-closable="false"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.show_user_provisioning_api_key.title')"
        event-type="MODAL_SHOW_USER_PROVISIONING_API_KEY"
        @show="onShow"
    >
        <p>{{ trans('modals.show_user_provisioning_api_key.description') }}</p>
        <div class="user-provisioning-api-key-input-wrapper">
            <TextInput
                ref="user-provisioning-api-key-input"
                :read-only="true"
                :initial-value="apiKey!"
                class="panel-row session-name-input"
            />

            <ButtonCircular
                v-tooltip="'labels.copy'"
                class="small"
                icon="icon_copy"
                @trigger="onApiKeyCopy($event)"
            />
        </div>

        <template #buttons>
            <ButtonPrimary
                caption="labels.okay"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">
import {defineComponent, nextTick} from 'vue';
import Modal from '@/Vue/Modals/Modal.vue';
import {trans} from '@/Utility/Helpers/trans';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import {Clipboard} from '@/Utility/Clipboard';

export default defineComponent({
    components: {
        ButtonCircular,
        TextInput,
        ButtonPrimary,
        Modal,
    },

    data() {
        return {
            apiKey: null as string | null,
        };
    },

    methods: {
        trans,

        onShow(apiKey: string) {
            this.apiKey = apiKey;

            nextTick(() => {
                (this.$refs['user-provisioning-api-key-input'] as InstanceType<typeof HTMLInputElement>).focus();
            });
        },

        onClickApply() {
            (this.$refs.modal as InstanceType<typeof Modal>).hide();
        },

        onApiKeyCopy(e: Event) {
            (e.target as HTMLInputElement).blur();
            Clipboard.writeString(this.apiKey!);
        },
    },
});
</script>

<style lang="scss" scoped>

dialog {
    width: 450px;

    .panel-row {
        &:deep(label) {
            font-family: var(--font-family-condensed-demibold);
            display: block;
            padding-bottom: 8px;
        }
    }

    p {
        margin-top: 16px;
    }

    .user-provisioning-api-key-input-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 24px;

        &:deep(.textinput) {
            flex-grow: 1;
            margin: 0;
        }

        button {
            margin: 0;
        }
    }
}


</style>
