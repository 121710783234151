<template>
    <Modal
        ref="modal"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="title"
        :user-closable="true"
        event-type="MODAL_IMPORT_USERS"
        @show="onShow"
    >
        <table v-if="userImport" class="table table-bordered">
            <thead>
                <tr class="table-active">
                    <td class="row-number">#</td>
                    <td>{{ trans('labels.lastname') }}</td>
                    <td>{{ trans('labels.firstname') }}</td>
                    <td>{{ trans('labels.email') }}</td>
                    <td>{{ trans('labels.tenant_role') }}</td>
                    <td>{{ trans('labels.role') }}</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(entry, i) in userImport" :key="i">
                    <td class="row-number">
                        {{ i }}
                    </td>
                    <td :class="{invalid: entry.lastName.isInvalid}">
                        {{ entry.lastName.value }}
                    </td>
                    <td :class="{invalid: entry.firstName.isInvalid}">
                        {{ entry.firstName.value }}
                    </td>
                    <td :class="{invalid: entry.email.isInvalid}">
                        {{ entry.email.value }}
                    </td>
                    <td :class="{invalid: entry.tenantRole.isInvalid}">
                        {{ entry.tenantRole.value }}
                    </td>
                    <td :class="{invalid: entry.role.isInvalid}">
                        {{ entry.role.value }}
                    </td>
                </tr>
            </tbody>
        </table>

        <template #buttons>
            <ButtonSecondary
                caption="labels.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                :disabled="!userImport?.isValid"
                caption="labels.import"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>

    </Modal>
</template>

<script lang="ts">

import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent, inject} from 'vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import {trans} from '@/Utility/Helpers';
import type UserImport from '@/Models/User/Import/UserImport';
import {userServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import EventType from '@/Utility/EventType';

export default defineComponent({
    components: {
        ButtonSecondary,
        ButtonPrimary,
        Modal
    },
    data() {
        return {
            title: null as string | null,
            userImport: null as UserImport | null,
            userService: inject(userServiceKey)!,
        };
    },
    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    },
    methods: {
        trans,

        onShow(title: string, userImport: UserImport) {
            this.title = title;
            this.userImport = userImport;
        },

        async onClickApply() {
            try {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.importing_users'));
                await this.userService.importUsers(this.userImport!.validatedUserList);
            } catch (e: any) {
                this.onUserImportFailed(e);
            } finally {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                this.modal.hide();
            }
        },

        onClickCancel() {
            this.modal.hide();
        },

        onUserImportFailed(e: any) {
            if (e.message) {
                const message = e.message + '\n\n' + trans('modals.import_users.no_users_imported');
                this.$root?.showErrorDialog(message);
            } else {
                this.$root?.showErrorDialog(e);
            }
        }
    }
});
</script>

<style lang="scss" scoped>

dialog {
    width: 800px;

    .table-active > td {
        background-color: var(--color-anthracite);
        color: white;
        font-family: var(--font-family-default);
        font-weight: 400;
    }

    td.row-number {
        text-align: right;
        font-variant-numeric: tabular-nums;
    }

    td.invalid {
        background-color: var(--color-signal);
        color: white;
    }
}

</style>
