<template>

    <main id="layout-main" :data-deleting="isDeleting">

        <PageHeader
            :buttons="headerButtons"
            :page-title="trans('tenants.update.headline')"
            :show-back-button="true"
        />

        <TenantUpdateForm
            :tenant="tenant"
        />

        <ManageLocalFeaturesForm
            :tenant-uid="tenant.uid"
        />

        <ModalDeleteTenant />
        <ModalProgress />
        <ModalNotification />
    </main>

</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {trans} from '@/Utility/Helpers/trans';
import PageHeader from '@/Vue/Common/PageHeader.vue';
import ManageLocalFeaturesForm from '@/Vue/Features/Local/ManageLocalFeaturesForm.vue';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import TenantUpdateForm from '@/Vue/Tenants/TenantUpdateForm.vue';
import Tenant from '@/Models/Tenant/Tenant';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import PageHeaderButton from '@/Utility/PageHeaderButton';
import EventType from '@/Utility/EventType';
import {Permission} from '@/Models/User/Permission';
import ModalDeleteTenant from '@/Vue/Modals/ModalDeleteTenant.vue';
import {tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import AuthorizationError from '@/Errors/AuthorizationError';
import {route} from '@/Utility/Helpers/route';

export default defineComponent({
    name: 'PageTenantUpdate',

    components: {
        ModalDeleteTenant,
        ModalProgress,
        TenantUpdateForm,
        ModalNotification,
        ManageLocalFeaturesForm,
        PageHeader,
    },

    props: {
        tenantJson: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            tenant: new Tenant(JSON.parse(this.tenantJson)),

            tenantService: inject(tenantServiceKey)!,

            headerButtons: {
                deleteTenant: new PageHeaderButton({
                    caption: trans('labels.delete'),
                    tooltip: 'buttons.tenants.delete',
                    icon: 'icon_delete',
                    callback: this.onDeleteClicked,
                    visible: this.canDelete,
                }),
            },

            events: new Map([
                [EventType.MODAL_DELETE_TENANT_APPLY, this.onConfirmDeleteTenant],
                [EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav],
            ]),
        };
    },

    computed: {
        isDeleting(): boolean {
            if (this.tenantService.isDeleting) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.deleting_tenant'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },
    },

    mounted() {
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });

        this.headerButtons.deleteTenant.visible = this.canDelete();
    },

    beforeUnmount() {
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    methods: {
        trans,

        canDelete() {
            return this.$gate.allows(Permission.ability(Permission.TenantsDelete()), this.tenant);
        },

        onClickHeaderNav(buttonConfig: PageHeaderButton) {
            buttonConfig.callback?.call(this, buttonConfig);
        },

        onDeleteClicked() {
            this.$globalEvents.emit(EventType.MODAL_DELETE_TENANT_SHOW, this.tenant);
        },

        onConfirmDeleteTenant() {
            this.tenantService
                .deleteTenant(this.tenant)
                .then(() => {
                    window.location.href = route('tenants.index');
                })
                .catch(this.onErrorApi);
        },

        onErrorApi(error: string | Error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root!.forceLogout;
            }

            this.$root!.showErrorDialog(error);
        },
    }
});
</script>

<style lang="css" scoped>

</style>
